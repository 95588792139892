import swal from 'sweetalert';
import { apiAxios, baseApiAxios } from './api';
import {
  addCopyButton,
  addLoadAccountDetailsButton,
  addLoadVPCDetailsButton,
  checkValueAgainstList,
  encodeEntities,
  getWindowPosition,
  rowDetailsClickHandler,
  scrollToPosition,
  configureTableColumns,
  showErrorFromApiOperation,
  accountInfoFields,
  hasAccess,
  displayError,
  displayErrorPopup,
} from './main';
import { addSpinner, addButtonSpinner, addTableSpinner, removeSpinners, addSpinners } from './sidebar';
import { loadComponent } from '../../../js/load_component';
import NetworksListPage from '../../NetworksListPage';
import { accountIdPattern } from './accounts';
import VPCDropdown from '../../jsx/components/forms/VPCDropdown';
import VPCTabs from '../../jsx/components/forms/VPCTabs';
import { initDataTable } from './datatable';
import { Modal, Tooltip } from 'bootstrap';
import { enableFeatureFlagControlledElements } from './feature_flags';

export const vpcPattern = /^vpc-(([a-z0-9]{8})|([a-z0-9]{17}))$/;
export const cidrPattern =
  /^((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(\/(1[6-9]|2\d|3[0-2]))$/;

export const vpcTableColumns = [
  { id: 'select_col' },
  { id: 'vpc_id_col', name: 'VPC ID' },
  { id: 'vpc_name_col', name: 'Name' },
  { id: 'cidr_col', name: 'Primary CIDR' },
  { id: 'secondary_cidrs_col', name: 'Secondary CIDRs' },
  { id: 'creation_date_col', name: 'Creation Date' },
  { id: 'aws_account_id_col', name: 'Account ID' },
  { id: 'aws_region_col', name: 'Region' },
  { id: 'network_type_col', name: 'Network Type' },
  { id: 'ipv6_cidr_col', name: 'IPv6 CIDR' },
  { id: 'ipv6_upgraded_col', name: 'IPv6 Upgraded' },
  { id: 'ipv6_pool_id_col', name: 'FPC IPv6 Pool ID' },
  { id: 'aws_ipv6_cidr_id_col', name: 'AWS IPv6 CIDR ID' },
  { id: 'netbond_cidr_col', name: 'Netbond CIDR' },
  { id: 'domain_name_col', name: 'Domain Name' },
  { id: 'domain_name_servers_col', name: 'DNS Server' },
  { id: 'dx_col', name: 'DirectConnect' },
  { id: 'subnets_col', name: 'Subnets' },
  { id: 'flow_logs_col', name: 'FlowLogs' },
  { id: 'query_logging_configs_col', name: 'R53R Query Logs' },
  { id: 'vpc_peerings_col', name: 'VPC Peerings' },
  { id: 'tgw_attachments_col', name: 'TGW Attachments' },
  { id: 'vpn_gateways_col', name: 'VPN Gateways (VGWs)' },
  { id: 'internet_gateways_col', name: 'Internet Gateways (IGWs)' },
  { id: 'nat_gateways_col', name: 'NAT Gateways' },
  { id: 'gateway_endpoints_col', name: 'VPC (Gateway) Endpoints' },
  { id: 'interface_endpoints_col', name: 'VPC (Interface) Endpoints' },
  { id: 'enis_col', name: 'Network Interfaces' },
  { id: 'r53r_rules_col', name: 'Associated R53R Rules' },
  { id: 'ips_number_col', name: 'Number IPs' },
  { id: 'ips_used_col', name: 'Used IPs' },
  { id: 'ips_free_col', name: 'Free IPs' },
  { id: 'intranet_ips_number_col', name: 'Number IPs (Intranet)' },
  { id: 'intranet_ips_used_col', name: 'Used IPs (Intranet)' },
  { id: 'intranet_ips_free_col', name: 'Free IPs (Intranet)' },
  { id: 'compliance_status_col', name: 'Compliance Status' },
  { id: 'status_col', name: 'VPC Status' },
  { id: 'status_update_col', name: 'Last Status Update (UTC)' },
  { id: 'actions_col', name: 'Actions' },
];
const vpcDbAttributes = [
  'id',
  'uuid',
  'vpc_id',
  'name',
  'cidr',
  'secondary_cidrs',
  'creation_date',
  'aws_account_id',
  'aws_region',
  'network_type',
  'ipv6_cidr',
  'ipv6_upgraded',
  'ipv6_pool_id',
  'aws_ipv6_cidr_id',
  'netbond_cidr',
  'dhcp_options',
  'dx',
  'status',
  'status_update',
  'numbers',
  'comment',
  'compliance_status',
];
const vpcEditFields = [
  'network-cidr',
  'creation-date',
  'aws-account-id',
  'aws-region',
  'network-type',
  'netbond-cidr',
  'vpc-id',
  'edit-comment',
];
const vpcDropdownColumns = ['aws_region', 'network_type', 'status', 'dx', 'compliance_status', 'ipv6_upgraded'];
const vpcSearchColumns = [
  'cidr',
  'secondary_cidrs',
  'vpc_name_col',
  'comment',
  'creation_date',
  'aws_account_id',
  'ipv6_cidr',
  'ipv6_pool_id',
  'aws_ipv6_cidr_id',
  'netbond_cidr',
  'vpc_id',
  'domain_name_col',
  'domain_name_servers_col',
  'status_update',
];
const vpcSearchColumnsNumbers = [
  'numbers.subnets',
  'numbers.flow_logs',
  'numbers.query_logging_configs',
  'numbers.vpc_peerings',
  'numbers.tgw_attachments',
  'numbers.vpn_gateways',
  'numbers.internet_gateways',
  'numbers.nat_gateways',
  'numbers.enis',
  'numbers.associated_r53r_rules',
  'numbers.ips_number',
  'numbers.ips_used',
  'numbers.ips_free',
];

export const networkInfoFields = [
  { Name: 'Account Id', Value: 'aws_account_id' },
  { Name: 'VPC ID', Value: 'vpc_id' },
  { Name: 'Name', Value: 'name' },
  { Name: 'Primary CIDR', Value: 'cidr' },
  { Name: 'Secondary CIDRs', Value: 'secondary_cidrs' },
  { Name: 'Region', Value: 'aws_region' },
  { Name: 'Network Type', Value: 'network_type' },
  { Name: 'Comment', Value: 'comment' },
  { Name: 'Creation Date', Value: 'creation_date' },
  { Name: 'Netbond CIDR', Value: 'netbond_cidr' },
  { Name: 'On Premise Connection', Value: 'dx' },
  { Name: 'Compliance Status', Value: 'compliance_status' },
  { Name: 'Compliance Details', Value: 'compliance_details' },
  { Name: 'VPC Status', Value: 'status' },
  { Name: 'Order Id', Value: 'order_id' },
  { Name: 'Error Details', Value: 'error_details' },
  { Name: 'Last Status Update (UTC)', Value: 'status_update' },
];
const networkDeleteStatus = ['active', 'NewVpc', 'UpdateInProgress', 'activeNoInternetConnection', undefined];
const networkNoDeleteStatus = [
  'AccessDenied',
  'InvalidVpcID.NotFound',
  'AccountDeleted',
  'VpcDeleted',
  'VpcDeletionFailed',
  'DeletionInProgress',
];
const networkNoTriggerUpdateStatus = ['InvalidVpcID.NotFound', 'AccountDeleted', 'VpcDeleted'];

let dashboardShowAllNetworks = false;

export const detailRowsVPCs = [];

// export function hideNetworkEditForm() {
//   $('#edit-vpc-form').hide();
// }

function removeNetworkFormWarning() {
  $('#form-error').hide();
  $('#form-success').hide();

  vpcEditFields.forEach(function (item) {
    $('#form_group_' + item).removeClass('has-success');
    $('#form_group_' + item).removeClass('has-error');
  });
}

export function validateNetworkForm() {
  removeNetworkFormWarning();

  // AWS ACCOUNT ID
  if (!accountIdPattern.test($('#aws-account-id')[0].value)) {
    $('#form_group_aws-account-id').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Must be a valid AWS Account ID!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_aws-account-id').addClass('has-success');
  }

  // AWS REGION
  if (!$('#aws-region')[0].value) {
    $('#form_group_aws-region').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Region can not be empty.');
    $('#form-error').show();
    return;
  } else {
    $('#form_group_aws-region').addClass('has-success');
  }

  // NETWORK TYPE
  if (!$('#network-type')[0].value) {
    $('#form_group_network-type').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Network Type can not be empty.');
    $('#form-error').show();
    return;
  } else {
    $('#form_group_network-type').addClass('has-success');
  }

  // VPC ID
  if (!vpcPattern.test($('#vpc-id')[0].value)) {
    $('#form_group_vpc-id').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Must be a valid VPC ID.');
    $('#form-error').show();
    return;
  } else {
    $('#form_group_vpc-id').addClass('has-success');
  }

  // NETWORK CIDR
  if (!cidrPattern.test($('#network-cidr')[0].value)) {
    $('#form_group_network-cidr').addClass('has-error');
    $('#form-error').html(
      '<strong>Warning: </strong> Network CIDR must be a valid CIDR. Network size must be between /16 and /32.',
    );
    $('#form-error').show();
    return;
  } else {
    $('#form_group_network-cidr').addClass('has-success');
  }

  // NETBOND CIDR
  if ($('#netbond-cidr')[0].value) {
    if (!cidrPattern.test($('#netbond-cidr')[0].value)) {
      $('#form_group_netbond-cidr').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Netbond CIDR must be a valid CIDR.');
      $('#form-error').show();
      return;
    } else {
      $('#form_group_netbond-cidr').addClass('has-success');
    }
  }

  // CREATION DATE
  if (!$('#creation-date')[0].value) {
    $('#form_group_creation-date').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Creation Date can not be empty.');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_creation-date').addClass('has-success');
  }

  updateVpcInDB();
}

// function createNewVpcRecord() {
//   removeNetworkFormWarning();

//   let permissions = localStorage.permissions ? localStorage.permissions : false;

//   $('#form-error').hide();
//   $('#form-success').hide();

//   $('#id')[0].value = '';
//   $('#uuid')[0].value = '';
//   $('#item_type')[0].value = 'VPC';
//   $('#aws-account-id')[0].value = '';
//   $('#vpc-id')[0].value = '';
//   $('#network-cidr')[0].value = '';
//   $('#network-type')[0].value = 'private';
//   $('#netbond-cidr')[0].value = '';
//   $('#creation-date')[0].value = '';
//   $('#aws-region')[0].value = 'eu-central-1';
//   $('#edit-comment')[0].value = '';
//   $('#delete-vpc').attr('disabled', true);
//   $('#delete-vpc').attr('hidden', true);
//   $('#aws-account-id').attr('disabled', false);
//   $('#vpc-id').attr('disabled', false);
//   $('#aws-region').attr('disabled', false);
//   $('#network-cidr').attr('disabled', false);
//   $('#network-type').attr('disabled', false);
//   $('#netbond-cidr').attr('disabled', false);
//   $('#creation-date').attr('disabled', false);
//   $('#edit-comment').attr('disabled', false);
//   $('#edit-vpc-form').show();
//   $('#network-size').attr('disabled', false);
//   $('#network-get-free').attr('disabled', false);
//   $('#netbond-size').attr('disabled', false);
//   $('#netbond-get-free').attr('disabled', false);

//   $('#submit-vpc')[0].value = 'Create';
//   $('#submit-vpc')[0].setAttribute('class', 'btn btn-success');

//   if (checkValueAgainstList('manage_networks', permissions)) {
//     $('#submit-vpc').attr('disabled', false);
//   } else {
//     $('#submit-vpc').attr('disabled', true);
//   }

//   window.scrollTo(0, 0);
// }

function updateVpcInDB() {
  swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to save this info into the database?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      okay: {
        text: 'Update',
        value: true,
        visible: true,
      },
    },
  }).then(function (response) {
    if (response) {
      let task = $('#submit-vpc')[0].value;

      let action;
      if (task === 'Update') {
        action = 'update-network-item';
      } else if (task === 'Create') {
        action = 'create-network-item';
      }

      let payload = {
        action: action,
        network_information: {
          aws_account_id: $('#aws-account-id')[0].value,
          vpc_id: $('#vpc-id')[0].value,
          aws_region: $('#aws-region')[0].value,
          cidr: $('#network-cidr')[0].value,
          network_type: $('#network-type')[0].value,
          creation_date: $('#creation-date')[0].value,
          item_type: $('#item_type')[0].value,
          uuid: $('#uuid')[0].value,
          id: $('#id')[0].value,
        },
      };

      if ($('#netbond-cidr')[0].value) {
        payload['network_information']['netbond_cidr'] = $('#netbond-cidr')[0].value;
      } else {
        payload['network_information']['netbond_cidr'] = false;
      }

      if ($('#edit-comment')[0].value) {
        payload['network_information']['comment'] = $('#edit-comment')[0].value;
      } else {
        payload['network_information']['comment'] = false;
      }

      baseApiAxios
        .postNetworkData(payload)
        .then(async () => {
          if (window.location.hash !== '#listvpc') {
            window.location.hash = '#listvpc';
            await loadComponent(NetworksListPage);
          }
        })
        .catch(err => {
          displayError(`<strong>Error</strong>: ${err.message}`);
        })
        .finally(() => removeSpinners());
    }
  });
}

function triggerVpcUpdate(row) {
  let payload;
  if (typeof row === 'number') {
    const dt = $('#table-vpcs').DataTable({
      retrieve: true,
    });
    payload = dt.row(row).data();
  } else if (typeof row === 'string') {
    payload = JSON.parse(row);
  } else {
    payload = row;
  }
  payload['action'] = 'trigger-update-vpc';

  addSpinner();

  baseApiAxios.displayResponseAsAlert(baseApiAxios.postNetworkData(payload));
}

export function triggerUpdateRouting(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-vpcs').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  swal({
    title: 'Are you sure?',
    text: 'Do you want to enforce a routing update for the VPC ' + data.vpc_id + '?',
    icon: 'warning',
    buttons: {
      update: {
        text: 'Update routing and keep tables',
        value: 'update-routing',
        className: 'swal-button swal-button--confirm',
      },
      updateDelete: {
        text: 'Update routing and delete old tables',
        value: 'update-routing-delete-tables',
        className: 'swal-button swal-button--confirm',
      },
      updateRollback: {
        text: 'Update routing and roll-back (for testing)',
        value: 'update-routing-roll-back',
        className: 'swal-button swal-button--confirm',
      },
      updateRollbackDelete: {
        text: 'Update routing, roll-back, delete tables (for testing)',
        value: 'update-routing-roll-back-delete',
        className: 'swal-button swal-button--confirm',
      },
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      let payload = {
        action: 'update-routing',
        account_id: data.aws_account_id || data.account_id,
        description: 'Update routing setup of VPC ' + data.vpc_id,
        region: data.aws_region || data.region_name || data.region,
        vpc_id: data.vpc_id,
      };
      switch (response) {
        case 'update-routing':
          break;
        case 'update-routing-delete-tables':
          payload['delete_source_tables'] = true;
          break;
        case 'update-routing-roll-back':
          payload['enforce_rollback'] = true;
          break;
        case 'update-routing-roll-back-delete':
          payload['enforce_rollback'] = true;
          payload['delete_source_tables'] = true;
          break;
      }

      // append the payload with the acknowledge_denied_region flag
      // since we ask use to confirm the action, the payload is always acknowledged
      payload['acknowledge_denied_region'] = true;

      baseApiAxios.displayResponseAsToast(baseApiAxios.createOrder(payload), 'Create Order');
    }
  });
}

export function upgradeVPCToIPv6(vpc_item) {
  let payload = {
    action: 'upgrade-vpc-ipv6',
    account_id: vpc_item.aws_account_id || vpc_item.account_id,
    vpc_id: vpc_item.vpc_id,
  };
  baseApiAxios.displayResponseAsToast(baseApiAxios.createOrder(payload), 'Create Order');
}

export function deleteVpcFromDBForm() {
  let network_information = {
    item_type: $('#item_type')[0].value,
    uuid: $('#uuid')[0].value,
    id: $('#id')[0].value,
    cidr: $('#network-cidr')[0].value,
  };

  deleteVpcFromDB(network_information);
}

function deleteVpcFromDB(data) {
  const vpc = typeof data === 'string' ? JSON.parse(data) : data;

  let content = document.createElement('div');
  content.innerHTML =
    '<p>Are you sure you want to delete VPC <strong><nobr>' +
    vpc.vpc_id +
    '</nobr></strong> with CIDR <strong>' +
    vpc.cidr +
    '</strong>?</p><p><strong>The DB item will be immediately removed from our database!</strong> ' +
    "The VPC itself won't be modified by this action and the CIDR range will be available again for new VPCs.</p>";

  swal({
    title: 'Are you sure?',
    content: content,
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Delete',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      const payload = {
        action: 'delete-network-item',
        network_information: vpc,
      };
      addSpinner();

      baseApiAxios
        .postNetworkData(payload)
        .then(async () => {
          if (window.location.hash !== '#listvpc') {
            window.location.hash = '#listvpc';
            await loadComponent(NetworksListPage);
          }
        })
        .catch(err => {
          displayError(`<strong>Error</strong>: ${err.message}`);
        })
        .finally(() => removeSpinners());
    }
  });
}

export function deleteVpcVerifyVpc(orderData) {
  if (typeof orderData === 'string') {
    orderData = JSON.parse(orderData);
  }

  const payload = {
    action: 'list-vpcs',
    region: orderData.region_name || orderData.region || orderData.aws_region,
    account_id: orderData.account_id || orderData.aws_account_id,
    vpc_id: orderData.vpc_id,
  };

  baseApiAxios
    .postNetworkData(payload)
    .then(result => {
      if (result.vpcs.length === 0) deleteVpcFromDB(orderData);
      else deleteVpcOrder(result, orderData);
    })
    .catch(() => {
      swal({
        title: 'VPC not Found',
        text:
          'Can not find the VPC in your AWS account. Please trigger the VPC update, reload the table after 30 to ' +
          '60 seconds and try again.',
        icon: 'error',
      });
    })
    .finally(() => removeSpinners());
}

function deleteVpcOrder(result, orderData) {
  if (typeof orderData === 'string') {
    orderData = JSON.parse(orderData);
  }

  if (!result.vpcs.length) throw `VPC not Found`;
  const vpc = result.vpcs.pop();

  let content = document.createElement('div');

  if (vpc.interfaces_customer && vpc.interfaces_customer > 0) {
    let leftEnis =
      '<p><strong>Customer network interface(s) left in your VPC: </strong><strong style="color: red">' +
      vpc.interfaces_customer +
      '</strong></p>';
    let warnMsg =
      '<p>You have to delete all resources (for example EC2 instances, NLBs or VPC Lambdas) before we can delete ' +
      'your VPC.</p>';

    let warnException = '';
    if (vpc.interfaces !== vpc.interfaces_customer) {
      warnException =
        "<p><strong>Exception: </strong>You don't have to delete " +
        (vpc.interfaces - vpc.interfaces_customer) +
        ' Transit Gateway interface(s).<p>';
    }

    content.innerHTML = leftEnis + warnMsg + warnException;

    swal({
      title: "We can't delete your VPC",
      content: content,
      icon: 'error',
    });
  } else {
    let warningMessage =
      '<p>Are you sure you want to delete VPC <strong><nobr>' +
      (vpc.vpc_id || orderData.vpc_id) +
      '</nobr></strong> with CIDR <strong>' +
      (vpc.vpc_cidr || orderData.cidr) +
      "</strong>?</p><p>If you confirm, you'll create a new order " +
      'which will <strong><nobr>delete your VPC</nobr></strong> from your AWS Account!</p>';

    warningMessage +=
      "<p><strong>Be aware:</strong> If you delete a Private VPC, you can't use the " +
      'same CIDR again for another VPC for the next 30 days. Also, ' +
      'your Tufin requests for this CIDR will be deleted within the next 30 days.</p>';

    content.innerHTML = warningMessage;

    swal({
      title: 'Are you sure?',
      content: content,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
        },
        reset: {
          text: 'Create Order',
          value: true,
          className: 'swal-button swal-button--confirm swal-button--danger',
        },
      },
      dangerMode: true,
    }).then(function (response) {
      if (response) {
        let payload = {
          action: 'destroy-vpc',
          account_id: orderData.account_id || orderData.aws_account_id,
          cidr: vpc.vpc_cidr || orderData.cidr,
          description: 'Destroy VPC ' + orderData.vpc_id + ' with CIDR ' + orderData.cidr,
          region: orderData.region || orderData.aws_region,
          vpc_id: vpc.vpc_id || orderData.vpc_id,
        };

        addSpinner();
        baseApiAxios.displayResponseAsToast(baseApiAxios.createOrder(payload), 'Create Order', () =>
          $('.selectpicker').val('').selectpicker('refresh'),
        );
      }
    });
  }
}

export function triggerTgwAttachment(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-vpcs').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  swal({
    title: 'Are you sure?',
    text: 'Do you want to associate the Shared Service TGW with VPC ' + data.vpc_id + '?',
    icon: 'warning',
    buttons: {
      associate: {
        text: 'Associate TGW',
        value: 'associate',
        className: 'swal-button swal-button--confirm',
      },
      associateAvoidCleanUp: {
        text: 'Associate TGW & Keep Old Route Table',
        value: 'associate-tgw-route-table-avoid-cleanup',
        className: 'swal-button swal-button--confirm',
      },
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      let payload = {
        action: 'attach-transitgateway',
        account_id: data.aws_account_id || data.account_id,
        description: 'Attach Shared Service TGWs to VPC ' + data.vpc_id,
        network_type: data.network_type,
        region: data.aws_region || data.region_name || data.region,
        vpc_id: data.vpc_id,
      };
      switch (response) {
        case 'associate-tgw-route-table-avoid-cleanup':
          payload['cleanup_route_tables'] = false;
          break;
      }

      // append the payload with the acknowledge_denied_region flag
      // since we ask use to confirm the action, the payload is always acknowledged
      payload['acknowledge_denied_region'] = true;

      baseApiAxios.displayResponseAsToast(baseApiAxios.createOrder(payload), 'Create Order');
    }
  });
}

export function deleteVpc(row) {
  let data;
  if (typeof row === 'number') {
    const dt = $('#table-vpcs').DataTable({
      retrieve: true,
    });
    data = dt.row(row).data();
  } else if (typeof row === 'string') {
    data = JSON.parse(row);
  } else {
    data = row;
  }

  let vpc = {
    item_type: data.item_type,
    uuid: data.uuid,
    id: data.id,
    cidr: data.cidr,
    vpc_id: data.vpc_id,
    account_id: data.aws_account_id || data.account_id,
    region: data.aws_region || data.region_name || data.region,
    status: data.status,
    network_type: data.network_type,
  };

  if (networkDeleteStatus.indexOf(vpc.status) < 0 && vpc.status !== 'CreationInProgress') {
    deleteVpcFromDB(vpc);
  } else {
    deleteVpcVerifyVpc(vpc);
  }
}

export function initNetworkStatisticsTable(tableId, sizes, tableData) {
  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
    ],
    [
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'region',
        name: 'region_col',
        title: 'Region',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'used_ips',
        name: 'used_ips_col',
        title: 'Used IP Addresses',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'available_ips',
        name: 'available_ips_col',
        title: 'Available IP Addresses',
        createdCell: addCopyButton,
      },
      ...sizes.map(size => {
        return {
          visible: false,
          defaultContent: '0',
          orderable: true,
          searchable: true,
          data: `size_${size}`,
          name: `size_${size}_col`,
          title: `Size /${size}`,
          createdCell: addCopyButton,
        };
      }),
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'range_usage',
        name: 'range_usage_col',
        title: 'Range Usage',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          if (cellData < 50.0) {
            $(td).addClass('portal-success-background');
          } else if (cellData < 75.0) {
            $(td).addClass('portal-warning-background');
          } else if (cellData <= 100.0) {
            $(td).addClass('portal-danger-background');
          }
        },
        render: function (cellData) {
          return `${cellData.toFixed(2)}%`;
        },
      },
    ],
    null,
    null,
  );

  const dt = $('#' + tableId).DataTable();
  dt.rows().remove();
  dt.rows.add(tableData).draw(false);
}

export function initNetworkTable(tableId) {
  configureTableColumns(tableId, vpcTableColumns);
  let permissions = localStorage.permissions ? localStorage.permissions : false;

  const hasPermissionToViewAll =
    checkValueAgainstList('manage_networks', permissions) ||
    checkValueAgainstList('view_all_networks', permissions) ||
    localStorage.account_areas;

  $('#show-all-networks-div').toggleClass('d-none', !hasPermissionToViewAll);

  $('#show-all-networks-switch')
    .attr('disabled', true)
    .attr('checked', dashboardShowAllNetworks)
    .on('change', function () {
      if (dashboardShowAllNetworks !== this.checked) {
        dashboardShowAllNetworks = this.checked;
        this.disabled = true;
        $('#' + tableId)
          .DataTable()
          .clear();

        loadNetworkData(tableId);
      }
    });

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      // {
      //   text: 'Create new VPC record',
      //   action: function () {
      //     if (checkValueAgainstList('manage_networks', permissions)) {
      //       createNewVpcRecord();
      //     } else {
      //       displayErrorPopup(null, "You don't have permissions to create new VPC records");
      //     }
      //   },
      //   titleAttr: 'Create new VPC record in the portal DB',
      // },
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
        width: '20px',
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'vpc_id',
        name: 'vpc_id_col',
        title: 'VPC ID',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadVPCDetailsButton(td);
          if (!vpcPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'name',
        name: 'vpc_name_col',
        title: 'Name',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        type: 'ip-address',
        data: 'cidr',
        name: 'cidr_col',
        title: 'Primary CIDR',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          if (!cidrPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        type: 'ip-address',
        data: 'secondary_cidrs',
        name: 'secondary_cidrs_col',
        title: 'Secondary CIDRs',
        render: function (data) {
          if (data) {
            try {
              return encodeEntities(data.join(', '));
            } catch (error) {
              return encodeEntities(data);
            }
          }
        },
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'ipv6_cidr',
        name: 'ipv6_cidr_col',
        title: 'IPv6 CIDR',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: 'false',
        orderable: true,
        searchable: true,
        data: 'ipv6_upgraded',
        name: 'ipv6_upgraded_col',
        title: 'IPv6 Upgraded',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'creation_date',
        name: 'creation_date_col',
        title: 'Creation Date',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'aws_account_id',
        name: 'aws_account_id_col',
        title: 'Account ID',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);
          if (!accountIdPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'aws_region',
        name: 'aws_region_col',
        title: 'Region',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          const pattern = /^[a-z]{2}-[a-z]{1,12}-\d$/;
          if (!pattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'network_type',
        name: 'network_type_col',
        title: 'Network Type',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'ipv6_pool_id',
        name: 'ipv6_pool_id_col',
        title: 'FPC IPv6 Pool ID',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'aws_ipv6_cidr_id',
        name: 'aws_ipv6_cidr_id_col',
        title: 'AWS IPv6 CIDR ID',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'netbond_cidr',
        name: 'netbond_cidr_col',
        title: 'Netbond CIDR',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          const pattern = /^(\d{1,3}\.){3}\d{1,3}(\/(\d|[1-2]\d|3[0-2]))$/;
          if (!pattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'dhcp_options.domain-name',
        name: 'domain_name_col',
        title: 'Domain Name',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'dhcp_options.domain-name-servers',
        name: 'domain_name_servers_col',
        title: 'DNS Server',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'dx',
        name: 'dx_col',
        title: 'DirectConnect',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.subnets',
        name: 'subnets_col',
        title: 'Subnets',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.flow_logs',
        name: 'flow_logs_col',
        title: 'Flow Logs',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.query_logging_configs',
        name: 'query_logging_configs_col',
        title: 'R53R Query Logs',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.vpc_peerings',
        name: 'vpc_peerings_col',
        title: 'VPC Peerings',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.tgw_attachments',
        name: 'tgw_attachments_col',
        title: 'TGW Attachments',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.vpn_gateways',
        name: 'vpn_gateways_col',
        title: 'VPN Gateways (VGWs)',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.internet_gateways',
        name: 'internet_gateways_col',
        title: 'Internet Gateways (IGWs)',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.nat_gateways',
        name: 'nat_gateways_col',
        title: 'NAT Gateways',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.gateway_endpoints',
        name: 'gateway_endpoints_col',
        title: 'VPC (Gateway) Endpoints',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.interface_endpoints',
        name: 'interface_endpoints_col',
        title: 'VPC (Interface) Endpoints',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.enis',
        name: 'enis_col',
        title: 'Network Interfaces',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.associated_r53r_rules',
        name: 'r53r_rules_col',
        title: 'Associated R53R Rules',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.ips_number',
        name: 'ips_number_col',
        title: 'Number IPs',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.ips_used',
        name: 'ips_used_col',
        title: 'Used IPs',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.ips_free',
        name: 'ips_free_col',
        title: 'Free IPs',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.intranet_ips_number',
        name: 'intranet_ips_number_col',
        title: 'Number IPs (Intranet)',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.intranet_ips_used',
        name: 'intranet_ips_used_col',
        title: 'Used IPs (Intranet)',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'numbers.intranet_ips_free',
        name: 'intranet_ips_free_col',
        title: 'Free IPs (Intranet)',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'compliance_status',
        name: 'compliance_status_col',
        title: 'Compliance Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (cellData === 'ok') {
            $(td).addClass('portal-success');
          } else if (cellData === 'warning') {
            $(td).addClass('portal-danger');
          } else {
            $(td).addClass('portal-warning');
          }
        },
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'VPC Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          $(td).removeClass('portal-success portal-warning portal-danger');

          if (cellData === 'active') {
            $(td).addClass('portal-success');
          } else if (
            cellData === 'NewVpc' ||
            cellData === 'UpdateInProgress' ||
            cellData === 'activeNoInternetConnection'
          ) {
            $(td).addClass('portal-warning');
          } else {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status_update',
        name: 'status_update_col',
        title: 'Last Status Update (UTC)',
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        width: '50px',
        createdCell: function (td, cellData, rowData, row) {
          const buttons = $('<div class="table-action-button-group">').appendTo(td);
          const accessibleVPC = hasAccess(rowData.aws_account_id, ['manage_networks']);
          if (networkNoTriggerUpdateStatus.indexOf(rowData.status) < 0) {
            // TRIGGER UPDATE BUTTON
            buttons.append(
              $('<button class="btn btn-custom btn-xs" data-bs-toggle="tooltip" title="Trigger DB Update">')
                .on('click', () => triggerVpcUpdate(row))
                .append('<i class="fas fa-sync-alt">'),
            );

            if (networkNoTriggerUpdateStatus.indexOf(cellData.status) < 0) {
              // EDIT BUTTON
              buttons.append(
                $(
                  '<button class="btn btn-custom btn-xs" data-bs-toggle="tooltip" title="Edit Item"' +
                    (accessibleVPC ? '' : ' disabled ') +
                    '>',
                )
                  .on('click', () => {
                    window
                      .open(
                        window.location.origin +
                          '/?account_id=' +
                          rowData.aws_account_id +
                          '&vpc_id=' +
                          rowData.vpc_id +
                          '#createnetwork',
                        '_blank',
                      )
                      .focus();
                  })
                  .append('<i class="fas fa-pencil-alt">'),
              );
            }
          }

          if (
            networkNoDeleteStatus.indexOf(rowData.status) < 0 ||
            checkValueAgainstList('manage_networks', permissions)
          ) {
            // DELETE BUTTON
            buttons.append(
              $(
                '<button class="btn btn-custom btn-xs" data-bs-toggle="tooltip" title="Delete VPC"' +
                  (accessibleVPC ? '' : ' disabled ') +
                  '>',
              )
                .on('click', () => deleteVpc(row))
                .append('<i class="fas fa-trash-alt">'),
            );
          }
        },
      },
    ],
    function (row, { status }) {
      if (status === 'AccountDeleted' || status === 'VpcDeleted') {
        $(row).addClass('row-deleted');
      } else if (status === 'InvalidVpcID.NotFound') {
        $(row).addClass('row-inactive');
      }
    },
    {
      search: {
        regex: false,
      },
      initComplete: function () {
        updateNetworkTableFooter(tableId);
      },
      drawCallback: function () {
        $(window).trigger('resize');
        scrollToPosition();
      },
      preDrawCallback: function () {
        getWindowPosition();
      },
    },
  );

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatChildRowNetwork });

  loadNetworkData(tableId);
}

async function loadNetworkData(tableId) {
  addSpinner(false);
  addTableSpinner();

  let payload = {
    action: 'list-network-items',
    attributes: vpcDbAttributes,
    item_type: 'VPC',
    list_all: dashboardShowAllNetworks,
    table_data: {
      length: 500,
    },
  };

  try {
    let { items, last_evaluated_key } = await baseApiAxios.postNetworkData(payload);
    appendNetworkData(items, tableId);

    while (last_evaluated_key) {
      // As long as there are still more db records to evaluate, we keep calling the api and loading the dataTable.
      const last_item = items[items.length - 1];
      const exclusive_start_key = {
        item_type: 'VPC',
        id: last_item.id,
        uuid: last_item.uuid,
        vpc_id: last_item.vpc_id,
      };
      payload['exclusive_start_key'] = exclusive_start_key;
      ({ items, last_evaluated_key } = await baseApiAxios.postNetworkData(payload));
      appendNetworkData(items, tableId);
    }
    // Then finally we re-enable to show-all switch, resize the window, and remove all loading spinners.
    $('#show-all-networks-switch').attr('disabled', false);
    $(window).trigger('resize');
    scrollToPosition();
  } catch (e) {
    displayErrorPopup(e);
  } finally {
    removeSpinners();
  }
}

async function appendNetworkData(new_items, tableId) {
  const dt = $('#' + tableId).DataTable();

  if (new_items) {
    // draw(false) to disable resetting the current page
    const existing_items = dt
      .rows()
      .data()
      .toArray()
      .map(item => `${item.vpc_id}_${item.aws_account_id}`);
    const items_to_append = new_items.filter(
      new_item => !existing_items.includes(`${new_item.vpc_id}_${new_item.aws_account_id}`),
    );
    dt.rows.add(items_to_append);
    dt.columns.adjust().draw(false);
  }
  updateNetworkTableFooter(tableId);
}

function updateNetworkTableFooter(tableId) {
  const dt = $('#' + tableId).dataTable();
  if (!dt.api().state()) return;
  const columnsState = dt.api().state().columns;

  dt.api()
    .columns()
    .every(function (col) {
      const that = this;
      const column = this;
      const title = column.header().innerHTML;
      const dataSource = column.dataSrc();
      const id = vpcTableColumns[column.index()].id;

      if (vpcDropdownColumns.indexOf(dataSource) > -1) {
        const select = $('<select><option value=""></option></select>')
          .appendTo($(column.footer()).empty())
          .on('change', function () {
            const val = $.fn.dataTable.util.escapeRegex($(this).val());
            column.search(val ? '^' + val + '$' : '', true, false).draw();
          });

        column
          .data()
          .unique()
          .sort()
          .each(function (data) {
            let opt = document.createElement('option');
            // necessary to deal with the new empty values for network_type
            let newData = data || '-';
            opt.innerText = newData;
            opt.value = newData;
            select.append(opt);

            if (
              data &&
              columnsState[col].search &&
              columnsState[col].search.search &&
              data.match(columnsState[col].search.search)
            ) {
              opt.selected = true;
            }
          });
      }

      if (vpcSearchColumns.indexOf(dataSource) > -1 || vpcSearchColumns.indexOf(id) > -1) {
        $(column.footer()).html('<input type="text" placeholder="Search ' + title + '" />');

        if (columnsState[col].search && columnsState[col].search.search) {
          column.footer().lastChild.value = columnsState[col].search.search;
        }

        $('input', that.footer()).on('keyup change', function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      }

      if (vpcSearchColumnsNumbers.indexOf(dataSource) > -1 || vpcSearchColumnsNumbers.indexOf(id) > -1) {
        $(column.footer()).html('<input type="text" placeholder="Search ' + title + '" />');
        $('input', that.footer()).on('keyup change', function () {
          if (this.value) {
            if (that.search() !== this.value) {
              that.search('^' + this.value + '$', true, false).draw();
            }
          } else {
            that.search('').draw();
          }
        });
      }
    });
}

export function getFreeNetworkCIDR() {
  const payload = {
    action: 'get-free-cidr',
    network_information: {
      aws_region: document.getElementById('aws-region')?.value,
      network_size: document.getElementById('network-size')?.value,
      network_type: document.getElementById('network-type')?.value,
      cidr_attribute: 'cidr',
    },
  };

  baseApiAxios
    .postNetworkData(payload)
    .then(result => {
      document.getElementById('network-cidr').value = result.cidr;
    })
    .catch(displayErrorPopup)
    .finally(() => removeSpinners());
}

export function getFreeNetbondCIDR() {
  const payload = {
    action: 'get-free-cidr',
    network_information: {
      network_size: document.getElementById('netbond-size')?.value,
      cidr_attribute: 'netbond_cidr',
    },
  };

  baseApiAxios
    .postNetworkData(payload)
    .then(result => {
      document.getElementById('netbond-cidr').value = result.cidr;
    })
    .catch(displayErrorPopup)
    .finally(() => removeSpinners());
}

async function formatChildRowNetwork(row) {
  const data = row.data();
  try {
    const vpc_result = await baseApiAxios.getNetworkItem(data.id, data.uuid, 'VPC');
    row.child(
      <div class="detailsContainer">
        <VPCTabs data={vpc_result} />
      </div>,
    );
    row.child()[0].setAttribute('class', 'rowDetails');
  } catch (err) {
    showErrorFromApiOperation('Loading VPC data failed.')(err);
  }
}

function ChildRowNetworkAccount({ account }) {
  return (
    <div class="detailsContent">
      <h4>Account Details</h4>
      <div>
        <InfoTable data={account} rowInfos={accountInfoFields} />
      </div>
    </div>
  );
}

export function formatChildRowNetworkAccount(data, container) {
  const mainContainer = <ChildRowNetworkAccount data={data} />;
  if (mainContainer) {
    container.appendChild(mainContainer);
  }
}

function ChildRowNetworkDXInterfaces({ interfaces }) {
  return (
    <div class="col-lg-12 col-md-12">
      <div class="detailsContent">
        <h4>Direct Connect Interfaces</h4>
        <div>
          <InfoTableWide
            data={interfaces}
            colInfos={[
              'associationId',
              'associationState',
              'directConnectGatewayId',
              'directConnectGatewayOwnerAccount',
              'virtualGatewayId',
              'virtualGatewayOwnerAccount',
              'virtualGatewayRegion',
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export function formatChildRowNetworkDXInterfaces({ dx_virtual_interfaces }, content) {
  const container = <ChildRowNetworkDXInterfaces interfaces={dx_virtual_interfaces} />;
  if (container) {
    content.appendChild(container);
  }
}

export function centralFillVPCs(result, functionParams, requestParams) {
  removeSpinners();

  if (result.status === 400) {
    $('#form-error').html('<strong>Warning: </strong>' + result.data.message + ' ');
    $('#form-error').show();
    return;
  }

  const { listId, disableSharedVPCs } = functionParams;

  const accountId = requestParams?.length ? requestParams[0]['account_id'] : undefined;
  const vpcs = (result && result.vpcs) || [];
  const select = document.getElementById(listId);
  jQuery.each(vpcs, function () {
    const optionDisabled = disableSharedVPCs && accountId && accountId !== this.owner_id;
    const opt = (
      <option
        value={this.vpc_id}
        data-content={`<span class="bs-dropdown-badge">${this.vpc_id}</span><span class="bs-dropdown-item-text">${
          this.vpc_name ? this.vpc_name : '-'
        }</span><span class="bs-dropdown-badge cidr">${this.vpc_cidr}</span>${
          this.vpc_ipv6_cidr ? `<span class='bs-dropdown-badge cidr'>${this.vpc_ipv6_cidr}</span>` : ''
        }${optionDisabled ? '<span class="bs-dropdown-badge warning bold-text">SHARED VPC</span>' : ''}`}></option>
    );
    opt.setAttribute('cidr', this.vpc_cidr);
    opt.setAttribute('ipv6_cidr', this.vpc_ipv6_cidr);
    opt.setAttribute('vpc_name', this.vpc_name);
    opt.setAttribute('interfaces', this.interfaces);
    opt.setAttribute('interfaces_customer', this.interfaces_customer);
    if (optionDisabled) opt.setAttribute('disabled', optionDisabled);

    if (this.subnets) {
      opt.setAttribute('subnets', JSON.stringify(this.subnets));
    }

    select.appendChild(opt);
  });

  $('.selectpicker').selectpicker('refresh');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
}

export function centralLoadVPCs(regionId, accountId, callback, callbackParams, detailed) {
  let region_name = document.getElementById(regionId).value;
  let account_id = document.getElementById(accountId)?.value.split(';')[0];
  if (!region_name || !account_id) return;

  let detailed_list = !!detailed;
  const accessibleVPC = hasAccess(account_id, ['manage_networks']);

  const { listId } = callbackParams;

  if (region_name && account_id && accessibleVPC) {
    const payload = {
      action: 'list-vpcs',
      region: region_name,
      account_id: account_id,
      detailed: detailed_list,
    };

    const sel = document.getElementById(listId);
    for (let i = sel.options.length - 1; i >= 0; i--) {
      sel.remove(i);
    }
    if (sel.options.length === 0) {
      const opt = document.createElement('option');
      opt.value = '';
      opt.innerText = '- Select a Network ID -';
      opt.disabled = true;
      opt.selected = true;
      sel.appendChild(opt);
    }

    $('.selectpicker').selectpicker('refresh');
    $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');

    addSpinner();
    addButtonSpinner();

    baseApiAxios
      .postNetworkData(payload)
      .then(result => {
        callback(result, ...(Array.isArray(callbackParams) ? callbackParams : [callbackParams]));
      })
      .catch(displayErrorPopup)
      .finally(() => removeSpinners());
  } else {
    console.debug('Account id or region name is missing.');
    removeSpinners();
  }
}

export function showCompareVPCModal(table_search, vpc_id_1, vpc_id_2) {
  let selected_vpc_1 = vpc_id_1 ? [vpc_id_1] : [];
  let selected_vpc_2 = vpc_id_2 ? [vpc_id_2] : [];

  if (table_search && !selected_vpc_1.length && !selected_vpc_2.length) {
    const vpcs_table = $('#table-vpcs');
    const vpcs_data_table = vpcs_table.DataTable();
    const expanded_rows = vpcs_table.find('tr.details');
    expanded_rows.each((idx, row_element) => {
      const row_data = vpcs_data_table.row(row_element).data();
      if (row_data.vpc_id) {
        if (idx === 0) {
          selected_vpc_1 = row_data.vpc_id;
        } else if (idx === 1) {
          selected_vpc_2 = row_data.vpc_id;
        }
      }
    });
  }

  removeSpinners();
  const modal_element = document.getElementById('sharedModal');
  const modal_dialog = document.getElementById('sharedModalDialog');
  if (modal_dialog) modal_dialog.setAttribute('data-type', 'custom-modal');

  const title = document.getElementById('sharedModalTitle');
  if (title) title.innerHTML = '<strong>Compare VPCs</strong>';
  const content = document.getElementById('sharedModalContent');
  if (content) {
    content.innerHTML = '';
    $(content).append(
      $('<p class="modal-subheadline">Select VPCs and compare them against each other</p>'),
      $('<div class="form-group row w-100 px-4" style="z-index:100;"/>')
        .append('<div class="form-label-text col-2"><span class="float-end">VPC 1</span></div>')
        .append(
          $('<div class="col-10" />').append(
            <VPCDropdown
              id="compare-modal-vpc-1"
              label="none"
              disableUrlParams
              selectedValues={selected_vpc_1}
              listAll
              inline
            />,
          ),
        ),
      $('<div class="form-group row w-100 px-4" />')
        .append('<div class="form-label-text col-2"><span class="float-end">VPC 2</span></div>')
        .append(
          $('<div class="col-10" />').append(
            <VPCDropdown
              id="compare-modal-vpc-2"
              label="none"
              disableUrlParams
              selectedValues={selected_vpc_2}
              listAll
              inline
            />,
          ),
        ),
    );
  }
  const footer = document.getElementById('sharedModalFooter');
  if (footer) {
    footer.innerHTML = '';
    const compare_button = $('<button type="button" class="btn btn-success" data-bs-dismiss="modal">Compare</button>');
    compare_button.on('click', () => {
      let vpc_id_params = [];
      const selected_1 = $('#compare-modal-vpc-1').val();
      const selected_2 = $('#compare-modal-vpc-2').val();
      if (selected_1) vpc_id_params.push(selected_1);
      if (selected_2) vpc_id_params.push(selected_2);
      if (vpc_id_params.length) {
        window.location.href = `?vpc_ids=${encodeURIComponent(vpc_id_params.join(','))}#comparevpc`;
      } else {
        window.location.href = '#comparevpc';
      }
    });
    $(footer).append(
      $('<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>'),
      compare_button,
    );
  }

  Modal.getInstance(modal_element).show();
}

export function showNetworkChartModal(
  updateSumOfTypesCallback,
  savedUsageData,
  updateSelectedTypesCallback,
  savedTypeData,
) {
  removeSpinners();
  const modal_element = document.getElementById('sharedModal');
  modal_element.classList.add('network-chart-configuration-modal');
  const modal_dialog = document.getElementById('sharedModalDialog');
  if (modal_dialog) modal_dialog.setAttribute('data-type', 'custom-modal');

  const title = document.getElementById('sharedModalTitle');
  if (title) title.innerHTML = '<strong>Number of VPCs Chart Settings</strong>';

  const quickLinksContainer = document.createElement('div');
  quickLinksContainer.id = 'config-network-chart-container';
  quickLinksContainer.className = 'config-network-chart-content';

  const previousSelection = sessionStorage.getItem('network-usage-chart-popup-network')?.split(',') || [];

  const checkboxLabels = Object.keys(savedTypeData).filter(label => label !== 'count');
  const checkboxAll = $(
    `<span><input type="checkbox" id="all" value="all"></input><label for="all">Sum of VPCs</label></span>`,
  );
  const checkboxContent = checkboxLabels.map(label =>
    $(
      `<span><input type="checkbox" id="${label}" class="dataset-select-checkbox" value="${label}" disabled="true"></input><label for="${label}">${label}</label></span>`,
    ),
  );

  const content = document.getElementById('sharedModalContent');
  if (content) {
    content.innerHTML = '';
    $(content).append($('<div class="dataset-checkbox-container"></div>').append(checkboxAll, checkboxContent));
  }

  const datasetCheckboxes = Array.from(document.querySelectorAll('.dataset-select-checkbox'));
  const allDatasetsCheckbox = checkboxAll.find('input[value="all"]');

  if (previousSelection.length !== 0) {
    allDatasetsCheckbox.prop('checked', false);
    datasetCheckboxes.forEach(checkbox => {
      if (previousSelection.includes(checkbox.value)) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
      checkbox.disabled = false;
    });
  } else {
    allDatasetsCheckbox.prop('checked', true);
  }
  allDatasetsCheckbox.on('change', evt => {
    if (evt.target.checked) {
      datasetCheckboxes.forEach(checkbox => {
        checkbox.checked = false;
        checkbox.disabled = true;
      });
    } else {
      datasetCheckboxes.forEach(checkbox => {
        checkbox.disabled = false;
        checkbox.checked = true;
      });
    }
  });

  const footer = document.getElementById('sharedModalFooter');
  if (footer) {
    footer.innerHTML = '';
    const update_button = $(
      '<button type="button" class="btn btn-success" data-bs-dismiss="modal">Update Chart</button>',
    );
    update_button.on('click', () => {
      if (allDatasetsCheckbox[0].checked) {
        sessionStorage.removeItem('network-usage-chart-popup-network');
        updateSumOfTypesCallback(savedUsageData);
      } else {
        const selectedDatasetLabels = datasetCheckboxes
          .filter(checkbox => checkbox.checked)
          .map(checkbox => checkbox.value);
        sessionStorage.setItem('network-usage-chart-popup-network', selectedDatasetLabels);
        updateSelectedTypesCallback(savedTypeData, selectedDatasetLabels);
      }
    });
    $(footer).append(
      $('<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>'),
      update_button,
    );
  }

  Modal.getInstance(modal_element).show();
}

export function showCidrChartModal(updateChartCallback, savedData) {
  removeSpinners();
  const modal_element = document.getElementById('sharedModal');
  modal_element.classList.add('cidr-chart-configuration-modal');
  const modal_dialog = document.getElementById('sharedModalDialog');
  if (modal_dialog) modal_dialog.setAttribute('data-type', 'custom-modal');

  const title = document.getElementById('sharedModalTitle');
  if (title) title.innerHTML = '<strong>Free CIDRs Chart Settings</strong>';

  const quickLinksContainer = document.createElement('div');
  quickLinksContainer.id = 'config-cidr-chart-container';
  quickLinksContainer.className = 'config-cidr-chart-content';

  const regionLabels = Object.keys(savedData);
  const sizeLabels = Object.keys(savedData[regionLabels[0]]);

  const previousSelection = JSON.parse(sessionStorage.getItem('network-usage-chart-popup-cidr') || '{}');

  const getCheckboxContent = (checkboxLabels, checkboxClassName, selectedValues) => {
    return checkboxLabels.map(label =>
      $(
        `<span><input type="checkbox" id="${label}" class="${checkboxClassName}" value="${label}" ${
          selectedValues && selectedValues.includes(label) ? 'checked' : ''
        }></input><label for="${label}">${label}</label></span>`,
      ),
    );
  };

  const content = document.getElementById('sharedModalContent');
  if (content) {
    content.innerHTML = '';
    $(content).append(
      $('<div class="dataset-checkbox-container"></div>').append(
        getCheckboxContent(regionLabels, 'dataset-select-checkbox-region', previousSelection.region || []),
        $('<br></br>'),
        getCheckboxContent(sizeLabels, 'dataset-select-checkbox-size', previousSelection.size || []),
      ),
    );
  }

  const datasetCheckboxesRegion = Array.from(document.querySelectorAll('.dataset-select-checkbox-region'));
  const datasetCheckboxesSize = Array.from(document.querySelectorAll('.dataset-select-checkbox-size'));

  const footer = document.getElementById('sharedModalFooter');
  if (footer) {
    footer.innerHTML = '';
    const update_button = $(
      '<button type="button" class="btn btn-success" data-bs-dismiss="modal">Update Chart</button>',
    );
    update_button.on('click', () => {
      const selectedRegionLabels = datasetCheckboxesRegion
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value);
      const selectedSizeLabels = datasetCheckboxesSize
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value);
      sessionStorage.setItem(
        'network-usage-chart-popup-cidr',
        JSON.stringify({ region: selectedRegionLabels, size: selectedSizeLabels }),
      );
      updateChartCallback(savedData, selectedRegionLabels, selectedSizeLabels);
    });
    $(footer).append(
      $('<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>'),
      update_button,
    );
  }

  Modal.getInstance(modal_element).show();
}

function getSubnetDropdownOption(subnet) {
  return (
    <option
      value={subnet.subnet_id}
      data-content={`<span class="bs-dropdown-badge">${subnet.subnet_id}</span><span class="bs-dropdown-badge cidr">${
        subnet.cidr
      }</span><span class="bs-dropdown-item-text">${
        subnet.name ? subnet.name : '-'
      }</span><span class="bs-dropdown-badge new">${subnet.subnet_type}</span>`}></option>
  );
}

function isValidSubnet(subnet) {
  return subnet.cidr || (subnet.cidr && subnet.ipv6_cidr); // cidr alone or cidr with ipv6_cidr
}

export function createVPCConnectivityTest(vpcData, callbackFunction) {
  removeSpinners();
  const modal_element = document.getElementById('sharedModal');
  const modal_dialog = document.getElementById('sharedModalDialog');
  if (modal_dialog) modal_dialog.setAttribute('data-type', 'custom-modal');
  const subnets = vpcData.subnets && Object.keys(vpcData.subnets).length > 0 ? vpcData.subnets : false;
  const title = document.getElementById('sharedModalTitle');
  if (title) title.innerHTML = '<strong>Create VPC Connectivity Test</strong>';
  const urlRegExp = new RegExp(/[-a-zA-Z0-9@:%._+~#=]{1,256}[.:][a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/);

  const removeTextField = evt => {
    const row = evt.target.closest('.text-array-field');
    if (row) row.remove();
  };
  const addTextField = evt => {
    const container = evt.target.closest('.text-array-field-container');
    const row = evt.target.closest('.text-array-field');
    const textField = row.querySelector('input[type="text"]');
    const button = row.querySelector('button');
    if (container && textField && button) {
      if (urlRegExp.test(textField.value)) {
        button.classList.add('btn-secondary');
        button.classList.remove('btn-success');
        button.innerHTML = `<i class="fas fa-minus"></i>`;
        button.onclick = removeTextField;
        textField.disabled = true;
        const newRow = document.createElement('div');
        newRow.className = 'text-array-field d-flex';
        $(newRow).html(
          <>
            <input
              type="text"
              class={textField.className}
              oninput="this.setCustomValidity('')"
              placeholder="https://"
            />
            <button class="btn btn-success mx-1" onclick={addTextField}>
              <i class="fas fa-plus"></i>
            </button>
          </>,
        );
        container.appendChild(newRow);
      } else {
        textField.setCustomValidity('Invalid URL');
      }
    }
  };

  const content = document.getElementById('sharedModalContent');
  if (content) {
    enableFeatureFlagControlledElements('network-proxy.enabled', 'feature-flag-custom-proxy-enabled');
    content.innerHTML = '';
    const modalMarkup = (
      <div class="modal-connectivity-test">
        <div class="form-group row w-100 px-4 modal-data-table-row">
          <div class="form-label-text col-4">
            <span class="float-end">Account Id</span>
          </div>
          <div class="form-value-text col-8">{vpcData.account_id}</div>
        </div>
        <div class="form-group row w-100 px-4 modal-data-table-row">
          <div class="form-label-text col-4">
            <span class="float-end">VPC Id</span>
          </div>
          <div class="form-value-text col-8">{vpcData.vpc_id}</div>
        </div>
        <div class="form-group row w-100 px-4 modal-data-table-row">
          <div class="form-label-text col-4">
            <span class="float-end">Region</span>
          </div>
          <div class="form-value-text col-8">{vpcData.region}</div>
        </div>
        <div class="form-group row w-100 px-4 modal-data-table-row">
          <div class="form-label-text col-4">
            <span class="float-end">Creation Date</span>
          </div>
          <div class="form-value-text col-8">{vpcData.creation_date}</div>
        </div>
        <div class="form-group row w-100 px-4 modal-data-table-row">
          <div class="form-label-text col-4">
            <span class="float-end">VPC Status</span>
          </div>
          <div class="form-value-text col-8">{vpcData.status}</div>
        </div>
        <div class="form-group row w-100 px-4 pt-3">
          <div class="form-label-text col-4">
            <span class="float-end">Subnet Id</span>
          </div>
          <div class="col-8">
            <select
              required
              class="form-control form-select selectpicker"
              data-live-search="true"
              data-show-subtext="true"
              data-size="10"
              data-none-selected-text="- Select a Subnet Id -"
              data-selected-text-format="count > 1"
              data-count-selected-text="{0} Subnets selected"
              data-dropdown-align-right="auto"
              id="modal_subnet_id"
              name="modal_subnet_id"
              onchange={evt => {
                const dropdownElement = evt.target.closest('.dropdown');
                dropdownElement.style.border = '';
              }}
              multiple>
              {/* Use the isValidSubnet function for filtering */}
              {subnets?.public?.length > 0 &&
                subnets.public
                  .filter(isValidSubnet) // Use the utility function here
                  .map(getSubnetDropdownOption)}
              {subnets?.private?.length > 0 &&
                subnets.private
                  .filter(isValidSubnet) // Use the utility function here
                  .map(getSubnetDropdownOption)}
              {subnets?.intranet?.length > 0 &&
                subnets.intranet
                  .filter(isValidSubnet) // Use the utility function here
                  .map(getSubnetDropdownOption)}
            </select>
          </div>
        </div>
        <div class="form-group row w-100 px-4">
          <div class="form-label-text col-4">
            <span class="float-end">
              On-premise URL
              <i
                class="fas fa-info-circle custom-tooltip-icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-custom-class="custom-tooltip"
                title="In addition to the default list of endpoints for testing."
              />
            </span>
          </div>
          <div id="on-premise-url-field-container" class="col-8 text-array-field-container">
            <div class="text-array-field d-flex">
              <input
                type="text"
                class="form-control form-url-input on_premise_url flex-grow-1"
                oninput="this.setCustomValidity('')"
                placeholder="https://"
              />
              <button class="btn btn-success mx-1" onclick={addTextField}>
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="feature-flag-custom-proxy-enabled d-none">
          <div class="form-group row w-100 px-4">
            <div class="form-label-text col-4">
              <span class="float-end">
                Custom Proxy URL
                <i
                  class="fas fa-info-circle custom-tooltip-icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  title="In addition to the default list of endpoints for testing."
                />
              </span>
            </div>
            <div id="proxy-url-field-container" class="col-8 text-array-field-container">
              <div class="text-array-field d-flex">
                <input
                  type="text"
                  class="form-control form-url-input custom_proxy_url flex-grow-1"
                  oninput="this.setCustomValidity('')"
                  placeholder="https://"
                />
                <button class="btn btn-success mx-1" onclick={addTextField}>
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    $(content).append(modalMarkup);
  }
  const footer = document.getElementById('sharedModalFooter');
  if (footer) {
    footer.innerHTML = '';
    const create_test_button = $(`<button id="submitButton" type="button" class="btn btn-success"></button>`).append(
      $('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"><span>'),
      'Start Test',
    );
    create_test_button.on('click', () => {
      addSpinner();
      addSpinners();
      const subnetsField = $('#modal_subnet_id');
      if (!subnetsField.val().length) {
        const subnetDropdownElement = subnetsField.closest('.dropdown')[0];
        if (subnetDropdownElement) subnetDropdownElement.style.border = '1px solid red';
        removeSpinners();
        return;
      }

      let onpremUrls = Array.from(document.querySelectorAll('input.on_premise_url[disabled]')).map(
        field => field.value,
      );
      let proxyUrls = Array.from(document.querySelectorAll('input.custom_proxy_url[disabled]')).map(
        field => field.value,
      );

      const addFieldValueToArray = (urlInputField, valueArray) => {
        if (!urlInputField.value) return true;
        if (urlRegExp.test(urlInputField.value)) {
          urlInputField.setCustomValidity('');
          valueArray.push(urlInputField.value);
          return true;
        } else {
          urlInputField.setCustomValidity('Invalid URL');
          return false;
        }
      };

      if (
        !addFieldValueToArray(document.querySelector('input.on_premise_url:not([disabled])'), onpremUrls) ||
        !addFieldValueToArray(document.querySelector('input.custom_proxy_url:not([disabled])'), proxyUrls)
      ) {
        removeSpinners();
        return;
      }

      const payload = {
        account_id: vpcData.account_id,
        region_name: vpcData.region,
        vpc_id: vpcData.vpc_id,
        subnet_ids: subnetsField.val(),
        customer_onprem_urls: onpremUrls,
        customer_proxy_urls: proxyUrls,
      };
      apiAxios
        .post(`/accounts/${vpcData.account_id}/vpcs/${vpcData.vpc_id}/tests/trigger`, payload)
        .then(response => {
          swal({
            title: `VPC Connectivity Test${response.data.status.length > 1 ? 's' : ''} successfully triggered`,
            text: `Successfully triggered ${response.data.status.length} new Connectivity Tests for:\n${$(
              '#modal_subnet_id',
            )
              .val()
              .join(', ')}`,
            className: 'modal-connectivity-test-text',
            icon: 'success',
          });
        })
        .catch(err => {
          console.error(err);
          swal({
            title: `Creating VPC Test failed`,
            text: err.response.data?.message + ':\n' + err.response.data?.errors?.join('\n'),
            className: 'modal-connectivity-test-text',
            icon: 'error',
          });
        })
        .finally(() => {
          callbackFunction();
          removeSpinners();
        });
      Modal.getInstance(modal_element).hide();
    });
    $(footer).append(
      $('<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>'),
      create_test_button,
    );
  }

  Modal.getInstance(modal_element).show();
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const _tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
  $('#modal_subnet_id').selectpicker('refresh');
}
