import { CONF } from './environment';
import { loadComponent } from '../../../js/load_component';

import FourWheelsCreatePage from '../../FourWheelsCreatePage';
import FourWheelsDetailsPage from '../../FourWheelsDetailsPage';
import FourWheelsListPage from '../../FourWheelsListPage';
import AccountDetailsPage from '../../AccountDetailsPage';
import AccountListPage from '../../AccountListPage';
import AccountClosePage from '../../AccountClosePage';
import AccountIcpExceptionPage from '../../AccountIcpExceptionPage';
import AdminBannersPage from '../../AdminBannersPage';
import AdminListApiKeysPage from '../../AdminListApiKeysPage';
import AdminFeatureFlagsPage from '../../AdminFeatureFlagsPage';
import AdminListInvalidPrincipalsPage from '../../AdminListInvalidPrincipalsPage';
import AdminListUserPermissionsPage from '../../AdminListUserPermissionsPage';
import AdminOrganizationsListPage from '../../AdminOrganizationsListPage';
import AdminOrganizationsMovePage from '../../AdminOrganizationsMovePage';
import AdminSettingsListPage from '../../AdminSettingsListPage';
import DashboardPage from '../../DashboardPage';
import FqdnManagementPage from '../../FqdnManagementPage';
import HostedZonesAssociatePage from '../../HostedZonesAssociatePage';
import HostedZonesCreatePage from '../../HostedZonesCreatePage';
import HostedZonesDetailsPage from '../../HostedZonesDetailsPage';
import HostedZonesDestroyPage from '../../HostedZonesDestroyPage';
import HostedZonesListPage from '../../HostedZonesListPage';
import HostedZonesOnboardPage from '../../HostedZonesOnboardPage';
import IdcManagementPage from '../../IdcManagementPage';
import ItsmManageApiKeysPage from '../../ItsmManageApiKeysPage';
import LoginChangePasswordPage from '../../LoginChangePasswordPage';
import LoginManageApiKeysPage from '../../LoginManageApiKeysPage';
import MarketplaceRequestRoleAccessPage from '../../MarketplaceRequestRoleAccessPage';
import NetworksUsagePage from '../../NetworksUsagePage';
import NetworksDetailsPage from '../../NetworksDetailsPage';
import NetworksComparePage from '../../NetworksComparePage';
import NetworksCreatePage from '../../NetworksCreatePage';
import NetworksDestroyPage from '../../NetworksDestroyPage';
import NetworksListPage from '../../NetworksListPage';
import NetworksListDxGwPage from '../../NetworksListDxGwPage';
import NetworkManagementPage from '../../NetworkManagementPage';
import NetworksPeerPage from '../../NetworksPeerPage';
import NetworksPeerDestroyPage from '../../NetworksPeerDestroyPage';
import OrdersDetailsPage from '../../OrdersDetailsPage';
import OrdersListPage from '../../OrdersListPage';
import RegionManagementPage from '../../RegionManagementPage';
import ScpChangelogManagementPage from '../../ScpChangelogManagementPage';
import ServiceUsersCreatePage from '../../ServiceUsersCreatePage';
import ServiceUsersDeletePage from '../../ServiceUsersDeletePage';
import UsersADAssignPage from '../../UsersADAssignPage';
import UsersADEnablePage from '../../UsersADEnablePage';
import UsersADRemovePage from '../../UsersADRemovePage';
import UsersADUpdatePage from '../../UsersADUpdatePage';
import UsersIdpPage from '../../UsersIdpPage';
import UsersListAndConfirmPage from '../../UsersListAndConfirmPage';
import TrainingAccountsListPage from '../../TrainingAccountsListPage';
import TrainingsListPage from '../../TrainingsListPage';
import TrainingsPlanPage from '../../TrainingsPlanPage';
import StatisticsNetworkPage from '../../StatisticsNetworkPage';
import StatisticsAccountPage from '../../StatisticsAccountPage';
import StatisticsOrderPage from '../../StatisticsOrderPage';
import StatisticsSeriesPage from '../../StatisticsSeriesPage';
import SiemOnboardingRequestPage from '../../SiemOnboardingRequestPage';
import SiemCreateKeyPage from '../../SiemCreateKeyPage';
import Admin4WheelsClusterPage from '../../Admin4WheelsClusterPage';

const pages = {
  '#create4wheels': { page: FourWheelsCreatePage, title: 'Create Cluster' },
  '#4wheelsdetails': { page: FourWheelsDetailsPage, title: 'Cluster Details' },
  '#list4wheels': { page: FourWheelsListPage, title: 'List Clusters' },
  '#manage4wheelsclusteroptions': { page: Admin4WheelsClusterPage, title: '4Wheels Guided Cluster Options' },
  '#accountdetails': { page: AccountDetailsPage, title: 'AWS Account Details' },
  '#listaccounts': { page: AccountListPage, title: 'List AWS Accounts' },
  '#closeaccount': { page: AccountClosePage, title: 'Close AWS Account' },
  '#icpaccountexception': { page: AccountIcpExceptionPage, title: 'Request ICP Exception' },
  '#managebanners': { page: AdminBannersPage, title: 'Manage Banners' },
  '#listapikeys': { page: AdminListApiKeysPage, title: 'List API Keys' },
  '#featureflags': { page: AdminFeatureFlagsPage, title: 'Manage Feature Flags' },
  '#listinvalidprincipals': { page: AdminListInvalidPrincipalsPage, title: 'List Invalid Principals' },
  '#listuserpermissions': { page: AdminListUserPermissionsPage, title: 'List User Permissions' },
  '#adminlistaccounts': { page: AdminOrganizationsListPage, title: 'List Accounts' },
  '#adminmoveaccount': { page: AdminOrganizationsMovePage, title: 'Move Accounts' },
  '#listsettings': { page: AdminSettingsListPage, title: 'List Settings' },
  '#dashboard': { page: DashboardPage, title: 'Dashboard' },
  '#networkstatistics': { page: StatisticsNetworkPage, title: 'Network Statistics' },
  '#accountstatistics': { page: StatisticsAccountPage, title: 'Account Statistics' },
  '#orderstatistics': { page: StatisticsOrderPage, title: 'Order Statistics' },
  '#seriesstatistics': { page: StatisticsSeriesPage, title: 'Series Statistics' },
  '#associatehostedzone': { page: HostedZonesAssociatePage, title: 'Associate Hosted Zone' },
  '#createhostedzone': { page: HostedZonesCreatePage, title: 'Create Hosted Zone' },
  '#hostedzonedetails': { page: HostedZonesDetailsPage, title: 'Hosted Zone Details' },
  '#destroyhostedzone': { page: HostedZonesDestroyPage, title: 'Destroy Hosted Zone' },
  '#listhostedzone': { page: HostedZonesListPage, title: 'List Hosted Zones' },
  '#onboardhostedzone': { page: HostedZonesOnboardPage, title: 'Onboard Hosted Zones' },
  '#itsm': { page: ItsmManageApiKeysPage, title: 'ITSM Event Bridge' },
  '#changepassword': { page: LoginChangePasswordPage, title: 'Change your Portal Password' },
  '#manageapikeys': { page: LoginManageApiKeysPage, title: 'Manage your Portal API Keys' },
  '#marketplacepurchases': { page: MarketplaceRequestRoleAccessPage, title: 'Marketplace & Reserved Instances' },
  '#networkusage': { page: NetworksUsagePage, title: 'Network Usage' },
  '#networkdetails': { page: NetworksDetailsPage, title: 'Network Details' },
  '#comparevpc': { page: NetworksComparePage, title: 'Compare VPCs' },
  '#createnetwork': { page: NetworksCreatePage, title: 'Create VPC' },
  '#destroyvpc': { page: NetworksDestroyPage, title: 'Destroy VPC' },
  '#listvpc': { page: NetworksListPage, title: 'List VPCs' },
  '#listdxgw': { page: NetworksListDxGwPage, title: 'List Direct Connect Gateways' },
  '#createpeering': { page: NetworksPeerPage, title: 'Create VPC Peering' },
  '#destroypeering': { page: NetworksPeerDestroyPage, title: 'Destroy VPC Peering' },
  '#orderdetails': { page: OrdersDetailsPage, title: 'Order Details' },
  '#orders': { page: OrdersListPage, title: 'List Orders' },
  '#createserviceuser': { page: ServiceUsersCreatePage, title: 'Create IAM Service User' },
  '#deleteserviceuser': { page: ServiceUsersDeletePage, title: 'Delete IAM Service User' },
  '#addaduser': { page: UsersADAssignPage, title: 'Assign AD User' },
  '#enableaduser': { page: UsersADEnablePage, title: 'Enable AD User' },
  '#removeaduser': { page: UsersADRemovePage, title: 'Remove AD User' },
  '#updateaduser': { page: UsersADUpdatePage, title: 'Update AD User' },
  '#listidps': { page: UsersIdpPage, title: 'List Identity Providers' },
  '#confirmusers': { page: UsersListAndConfirmPage, title: 'List and Confirm Users' },
  '#listTrainingAccounts': { page: TrainingAccountsListPage, title: 'List Training Accounts' },
  '#listTrainings': { page: TrainingsListPage, title: 'List Trainings' },
  '#planTraining': { page: TrainingsPlanPage, title: 'Plan Trainings' },
  '#siemonboarding': { page: SiemOnboardingRequestPage, title: 'SIEM Onboarding Request' },
  '#siemcreatekey': { page: SiemCreateKeyPage, title: 'Create SIEM Key' },
  '#fqdn': { page: FqdnManagementPage, title: 'FQDN Management' },
  '#idc': { page: IdcManagementPage, title: 'IDC Management' },
  '#region': { page: RegionManagementPage, title: 'Region Management' },
  '#network': { page: NetworkManagementPage, title: 'Network Management' },
  '#scp-changelog': { page: ScpChangelogManagementPage, title: 'SCP Changelog Management' },
  '': { title: 'Page not found' },
};

export const default_quick_links = [
  { icon: 'fa-user-check', label: 'Enable AD User', link: '#enableaduser' },
  { icon: 'fa-user-plus', label: 'Assign AD User', link: '#addaduser' },
  { icon: 'fa-user-minus', label: 'Remove AD User', link: '#removeaduser' },
  { icon: 'fa-coins', label: 'Create Hosted Zone', link: '#createhostedzone' },
];
export const quick_links = [
  { icon: 'fa-history', label: 'Orders', link: '#orders' },
  { icon: 'fa-history', label: 'Order Details', link: '#orderdetails' },
  { icon: 'fa-tasks', label: 'ITSM Event Bridge', link: '#itsm' },
  { icon: 'fa-user-circle', label: 'AWS Account Details', link: '#accountdetails' },
  { icon: 'fa-users', label: 'List AWS Accounts', link: '#listaccounts' },
  { icon: 'fa-user-slash', label: 'Close AWS Accounts', link: '#closeaccount' },
  { icon: 'fa-user-cog', label: 'Request ICP Exception', link: '#icpaccountexception' },
  { icon: 'fa-user-check', label: 'Enable AD User', link: '#enableaduser' },
  { icon: 'fa-user-edit', label: 'Update AD User', link: '#updateaduser' },
  { icon: 'fa-user-plus', label: 'Assign AD User', link: '#addaduser' },
  { icon: 'fa-user-minus', label: 'Remove AD User', link: '#removeaduser' },
  { icon: 'fa-user-plus', label: 'Create IAM Service User', link: '#createserviceuser' },
  { icon: 'fa-user-minus', label: 'Delete IAM Service User', link: '#deleteserviceuser' },
  { icon: 'fa-user-check', label: 'List and Confirm Users', link: '#confirmusers' },
  { icon: 'fa-network-wired', label: 'Network Details', link: '#networkdetails' },
  { icon: 'fa-highlighter', label: 'Compare VPCs', link: '#comparevpc' },
  { icon: 'fa-list-alt', label: 'List DX Gateways', link: '#listdxgw' },
  { icon: 'fa-list-alt', label: 'List VPCs', link: '#listvpc' },
  { icon: 'fa-plus-square', label: 'Create VPC', link: '#createnetwork' },
  { icon: 'fa-minus-square', label: 'Destroy VPC', link: '#destroyvpc' },
  { icon: 'fa-plus-square', label: 'Create VPC Peering', link: '#createpeering' },
  { icon: 'fa-minus-square', label: 'Destroy VPC Peering', link: '#destroypeering' },
  { icon: 'fa-th-list', label: 'Hosted Zone Details', link: '#hostedzonedetails' },
  { icon: 'fa-list-alt', label: 'List Hosted Zones', link: '#listhostedzone' },
  { icon: 'fa-plus-square', label: 'Create Hosted Zone', link: '#createhostedzone' },
  { icon: 'fa-minus-square', label: 'Destroy Hosted Zone', link: '#destroyhostedzone' },
  { icon: 'fa-check', label: 'Associate Hosted Zone', link: '#associatehostedzone' },
  { icon: 'fa-directions', label: 'Onboard Hosted Zone', link: '#onboardhostedzone' },
  { icon: 'fa-th-list', label: 'Cluster Details', link: '#4wheelsdetails' },
  { icon: 'fa-list-alt', label: 'List Clusters', link: '#list4wheels' },
  { icon: 'fa-plus-square', label: 'Create Cluster', link: '#create4wheels' },
  { icon: 'fa-list-alt', label: 'List Training Accounts', link: '#listTrainingAccounts' },
  { icon: 'fa-shopping-cart', label: 'Marketplace', link: '#marketplacepurchases' },
  { icon: 'fa-chart-line', label: 'Network Statistics', link: '#networkstatistics' },
  { icon: 'fa-chart-line', label: 'Account Statistics', link: '#accountstatistics' },
  { icon: 'fa-chart-line', label: 'Order Statistics', link: '#orderstatistics' },
  { icon: 'fa-chart-line', label: 'Series Statistics', link: '#seriesstatistics' },
  { icon: 'fa-clipboard-check', label: 'SIEM Onboarding Request', link: '#siemonboarding' },
  { icon: 'fa-clipboard-user', label: 'Create SIEM Key', link: '#siemcreatekey' },
];

export async function loadPage(hash) {
  sessionStorage.lastHash = hash;
  sessionStorage.lastSearch = window.location.search;

  const title = pages[hash]?.title;

  const pageComponent = pages[hash]?.page || require('../../DoesNotExistPage').default;

  if (
    [
      '#dashboard',
      '#networkusage',
      '#networkstatistics',
      '#accountstatistics',
      '#orderstatistics',
      '#seriesstatistics',
      '#fqdn',
      '#idc',
      '#scp-changelog',
    ].indexOf(hash) !== -1
  ) {
    $('#page-header-row').hide();
  } else {
    $('#page-header-row').show();
    $('#mainTitle').text(title);
  }

  document.title = CONF.tabPrefix + ' | ' + title;

  await loadComponent(pageComponent, '#mainPage');
}
