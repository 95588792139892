import { Mutex } from 'async-mutex';
import { baseApiAxios } from '../portal/api';

let mutexes = {};

export async function get_feature_flag_property(featureFlag, defaultValue) {
  // Split feature flag and property name
  const featureFlagNameAndProperty = featureFlag.split('.');
  const featureFlagName = featureFlagNameAndProperty[0];
  const featureFlagProperty = featureFlagNameAndProperty[1];

  let featureFlagData;

  if (!mutexes[featureFlagName]) mutexes[featureFlagName] = new Mutex();

  await mutexes[featureFlagName].runExclusive(async () => {
    // Look up in cache
    const now = Math.floor(Date.now() / 1000);
    const cacheItemName = `FeatureFlag.${featureFlagName}`;
    const cacheItem = JSON.parse(sessionStorage.getItem(cacheItemName));
    if (cacheItem && now - cacheItem['cachedAt'] <= 300) {
      featureFlagData = cacheItem['properties'];
    } else {
      // Retrieve data from API
      await baseApiAxios.getFeatureFlag(featureFlagName).then(dataFromAPI => {
        featureFlagData = dataFromAPI;
        sessionStorage.setItem(
          cacheItemName,
          JSON.stringify({
            cachedAt: now,
            properties: featureFlagData,
          }),
        );
        delete mutexes[featureFlagName];
      });
    }
  });

  // Return selected property or all properties
  if (featureFlagProperty) {
    let value = featureFlagData[featureFlagProperty];
    return value === undefined ? defaultValue : value;
  }
  return featureFlagData;
}

export function enableFeatureFlagControlledElements(featureFlag, className) {
  get_feature_flag_property(featureFlag).then(isEnabled => {
    console.log(`Feature flag ${featureFlag} is ${isEnabled ? 'enabled' : 'disabled'}`);
    if (isEnabled) {
      document.querySelectorAll(`.${className}`).forEach(element => {
        element.classList.remove('d-none');
      });
    }
  });
}

export function disableFeatureFlagControlledElements(featureFlag, className) {
  get_feature_flag_property(featureFlag).then(isEnabled => {
    console.log(`Feature flag ${featureFlag} is ${isEnabled ? 'enabled' : 'disabled'}`);
    if (!isEnabled) {
      document.querySelectorAll(`.${className}`).forEach(element => {
        element.classList.remove('d-none');
      });
    }
  });
}
