export default function ClassicADUserMgmtMessage() {
  return (
    <div class="row classic-ad-user-mgmt-message d-none">
      <div class="col-lg-12">
        <div class="card form-card">
          <div class="card-header">Discontinued Classic AD User Management</div>
          <div class="card-body">
            <div id="infobox" class="card infobox">
              <div class="card-body">
                <p>
                  This feature has been discontinued in the Self Service portal. To manage access to your AWS accounts,
                  please refer to the new IAM solution. Detailed guidance is available at{' '}
                  <a
                    href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/2_developyourapplication/gettingstarted/user_enabling_idc/idc/"
                    target="_blank"
                    rel="noopener noreferrer">
                    RightNow IAM Solution Documentation
                    <i class="px-1 fas fa-external-link-alt" />
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
