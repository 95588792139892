import { baseApiAxios } from './js/portal/api';
import { checkUserAccess, checkValueAgainstList, displayError, displaySuccess, InfoTableWide } from './js/portal/main';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import {
  assignADUserOrdersPolicyDistinction,
  readRolesFromAccounts,
  showTemporaryAccessConfiguration,
  updateListRolesButtonWithoutDefaults,
} from './js/portal/user-management';
import UserDropdown from './jsx/components/forms/UserDropdown';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import swal from 'sweetalert';
import { disableFeatureFlagControlledElements, enableFeatureFlagControlledElements } from './js/portal/feature_flags';
import ClassicADUserMgmtMessage from './jsx/components/tiles/ClassicADUserMgmtMessage';

export default function UsersADAssignPage() {
  const page = (
    <div>
      <ClassicADUserMgmtMessage />
      <div class="row classic-ad-user-mgmt-form d-none">
        <div class="col-lg-12">
          <form name="user-assign-form" id="user-assign-form" method="POST">
            <div class="card form-card">
              <div class="card-header">Assign AD User to IAM Role</div>
              <div class="card-body">
                <div id="form-error" class="alert alert-danger" style="display: none">
                  <ul id="form-error-list" />
                </div>
                <div id="form-success" class="alert alert-success" style="display: none">
                  <ul id="form-success-list" />
                </div>

                <div id="infobox" class="card infobox">
                  <div class="card-body">
                    <p>
                      With this form you can assign Active Directory users to existing AWS accounts. A validation of the
                      account email you typed in or already existing assignments can not be validated in this form. For
                      more information please have a look in the{' '}
                      <a
                        href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Developer Portal
                        <i class="px-1 fas fa-external-link-alt" />
                      </a>
                      .
                    </p>
                    <p>
                      <strong>Attention:</strong> If you don't find the target AD user, please{' '}
                      <a href="#enableaduser">enable the user first</a>.
                    </p>
                  </div>
                </div>

                <UserDropdown id="user-email" multiple />

                <div class="form-group row">
                  <label for="aws-account-id" class="col-form-label">
                    AWS Account ID
                  </label>
                  <div class="col my-auto">
                    <AccountDropdown
                      id="aws-account-id"
                      label="none"
                      onchange={(_eventTypeId, _eventCategory, _selectedOption, accountIds) => {
                        document.getElementById('submitButton').disabled = !checkUserAccess(
                          ['manage_users'],
                          accountIds,
                        );
                        updateListRolesButtonWithoutDefaults();
                      }}
                      multiple
                      short
                    />
                  </div>
                </div>

                {/* selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/ */}
                <div class="form-group row">
                  <label for="target-roles" class="col-form-label">
                    Target Roles
                  </label>
                  <div class="col">
                    <div class="input-group">
                      <select
                        required
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        id="target-roles"
                        name="target-roles"
                        data-size="10"
                        data-actions-box="false"
                        data-none-selected-text="- Select target IAM Roles -"
                        data-selected-text-format="count > 5"
                        data-max-options="5"
                        multiple>
                        <option value="" disabled>
                          - Select target IAM Roles -
                        </option>
                      </select>
                      <button
                        type="button"
                        class="btn btn-light-grey input-group-btn"
                        id="readRolesButton"
                        onclick={() => readRolesFromAccounts()}>
                        List Roles from Account(s)
                      </button>
                    </div>

                    <div id="infobox" class="card infobox mt-3">
                      <div class="card-body">
                        <p>
                          <strong>Pay attention:</strong> you won't get real full permissions in your AWS Account, even
                          if the role is called UserFull.
                        </p>
                        <ul>
                          <li>
                            <strong>UserFull:</strong> allow admin access to resources within your AWS account (but this
                            role is restricted via SCPs)
                          </li>
                          <li>
                            <strong>UserRO:</strong> allow read-only access to resources within your AWS account
                          </li>
                          <li>
                            <strong>BMWBillingRO:</strong> allow Read Only access to accounts billing and cost
                            information
                          </li>
                          <li>
                            <strong>BMWSecurityRO:</strong> allow Read Only access to security related configurations in
                            the account
                          </li>
                        </ul>
                        <p>
                          Find more information about the FPC default roles in the{' '}
                          <a
                            href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/overview_iam/iam/#fpc-default-roles"
                            target="_blank"
                            rel="noopener noreferrer">
                            Developer Portal
                            <i class="px-1 fas fa-external-link-alt" />
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row" id="temporary-access-checkbox" style="display: none">
                  <label class="col-form-label" />
                  <div class="col">
                    <label class="checkbox-inline">
                      <input
                        type="checkbox"
                        value="temporary-access"
                        id="temporary-access"
                        name="temporary-access"
                        onchange={() => showTemporaryAccessConfiguration()}
                      />
                      <strong>Assign temporary IAM role access</strong>
                    </label>
                  </div>
                </div>

                <div id="temporary-access-div">
                  <div class="form-group row">
                    <label class="col-form-label" />
                    <div class="col">
                      <div class="card infobox">
                        <div class="card-body">
                          <p>
                            Grant temporary access for the chosen number of hours. Time is counting once the order was
                            approved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row" id="hours-picker">
                    <label for="temporary-hours" class="col-form-label" id="hours-label">
                      Number of Hours
                    </label>
                    <div class="col">
                      <select class="form-select" id="temporary-hours" name="temporary-hours">
                        <option value="1">1 hour</option>
                        <option value="2">2 hours</option>
                        <option value="4">4 hours</option>
                        <option value="8" selected>
                          8 hours
                        </option>
                        <option value="10">10 hours</option>
                        <option value="12">12 hours</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row" id="form_group_comment">
                    <label for="temp-access-comment" class="col-form-label">
                      Comment
                    </label>
                    <div class="col">
                      <textarea
                        rows="3"
                        class="form-control"
                        id="temp-access-comment"
                        name="temp-access-comment"
                        placeholder="Reason for your access request"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer" align="right">
                <button type="submit" class="btn btn-success" id="submitButton" value="Assign">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  Assign
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  let numOrders = 0;
  let submitOrders = 0;

  function resultFunction() {
    const form = document.getElementById('user-assign-form');
    if (submitOrders >= numOrders) {
      removeSpinners();
      $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
      form.reset();
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
    }
  }

  async function onPageReady() {
    enableFeatureFlagControlledElements('classic-ad-user-mgmt.assign_ad_user_classic', 'classic-ad-user-mgmt-form');
    disableFeatureFlagControlledElements('classic-ad-user-mgmt.assign_ad_user_classic', 'classic-ad-user-mgmt-message');

    let permissions = localStorage.permissions ? localStorage.permissions : false;

    if (checkValueAgainstList('manage_users', permissions)) {
      $('#temporary-access-checkbox').show();
      $('#temporary-access').attr('checked', false);
    } else {
      $('#temporary-access-checkbox').hide();
      $('#temporary-access').attr('checked', false);
    }

    updateListRolesButtonWithoutDefaults();
    showTemporaryAccessConfiguration();

    $('#user-assign-form')
      .on('submit', function (e) {
        e.preventDefault();
      })
      .validate({
        debug: false,
        submitHandler: function () {
          addSpinner();
          addButtonSpinner();
          numOrders = 0;
          submitOrders = 0;
          const selectedAccounts = $('#aws-account-id').val();
          const accountIds = selectedAccounts.map(function (item) {
            return item.split(';')[0];
          });
          baseApiAxios.get('/iam/iam-roles', {}, { account_ids: accountIds.join(',') }).then(function (response) {
            if (response.account_roles && response.account_roles.length === 0) {
              swal({
                title: `No permissions for Account(s)`,
                icon: 'warning',
                content: (
                  <div>
                    <p align="left">
                      Can not read/update IAM roles for Account(s):
                      <br />
                      {accountIds.join(', ')}
                    </p>
                  </div>
                ),
              });
            }
            let orders = assignADUserOrdersPolicyDistinction(response);
            orders.ordersToBe.forEach(function (orderPayload) {
              numOrders++;
              baseApiAxios
                .createOrder(orderPayload)
                .then(() => {
                  submitOrders++;
                  resultFunction();
                })
                .catch(err => {
                  displayError(`<strong>Error:</strong> ${err.message}`);
                  resultFunction();
                });
            });
            removeSpinners();
            if (orders.ordersSummary.length > 0) {
              displaySuccess(
                `You've successfully created <strong>${orders.ordersSummary.length}</strong>  new order(s). Please check your orders for the current approval/execution status.`,
              );
              let colInfos = ['AccountID', 'User', 'Role', 'Status'];
              swal({
                title: 'Orders Summary',
                content: (
                  <div>
                    <p align="left">
                      Due to the <a href="">trust-relationship policy limit</a>, currently 4096 characters, some of your
                      orders might have been automatically cancelled.
                    </p>
                    <p align="left">
                      Please check the list below for ones which exceed this limit and are marked with status
                      "Cancelled".
                    </p>
                    <p align="left">
                      <strong>Hint: </strong>Remove AD Users from the target roles to reduce the policy size before
                      retrying.
                    </p>
                    <InfoTableWide data={orders.ordersSummary} colInfos={colInfos} rowNumbers={false} />
                  </div>
                ),
                className: 'swal-wide',
                buttons: {
                  okay: {
                    text: 'Understood',
                    value: true,
                    visible: true,
                  },
                },
              });
            }
          });
        },
      });
  }

  return [page, onPageReady];
}
