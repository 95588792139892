import { baseApiAxios } from '../../../js/portal/api';
import {
  checkValueAgainstList,
  createJsonCodeBlock,
  InfoTable,
  showErrorFromApiOperation,
} from '../../../js/portal/main';
import { addSpinner, removeSpinners, addButtonSpinner } from '../../../js/portal/sidebar';
import { checkResponsibility } from '../../../js/portal/dashboard';
import { COGNITO } from '../../../js/portal/auth';

function checkAlreadyApproved(approvers, userName) {
  let alreadyApproved = false;
  if (approvers) {
    Object.entries(approvers).forEach(([key, _]) => {
      if (userName.toLowerCase() === key.toLowerCase()) {
        alreadyApproved = true;
      }
    });
  }

  return alreadyApproved;
}

function reloadOrders() {
  const ordersTable = $('#table-orders');
  if (ordersTable?.length) {
    let dt = ordersTable.DataTable({
      retrieve: true,
    });
    dt.clear().draw();
    dt.ajax.reload();
    return;
  }
  $('select.selectpicker').trigger('reload-dropdown-options');
}

async function orderUpdateHandler(orderId, approvalStatus) {
  const approvalCommentInput = document.getElementById(`approval-comment-${orderId}`);

  // no native validator as it is not in a form
  if (approvalStatus !== 'retry' && approvalCommentInput && approvalCommentInput.value.length <= 0) {
    swal({
      title: 'Validation Error',
      text: 'Approval comment is required!',
      icon: 'error',
    });
    removeSpinners();
    return;
  }

  const payload = {
    approval: approvalStatus,
    approval_comment: approvalCommentInput?.value || '',
  };

  const approvalStatusList = ['approve', 'reject', 'retry'];

  try {
    addSpinner();
    approvalStatusList.forEach(item => {
      addButtonSpinner(`${orderId}-${item}-button`);
    });
    if (approvalCommentInput) approvalCommentInput.disabled = true;
    await baseApiAxios.updateOrder(orderId, payload);
    reloadOrders();
    removeSpinners();
  } catch (err) {
    showErrorFromApiOperation('Order update failed.')(err);
    removeSpinners();
  }
}

function renderApprovalProgressbar(orderDetails, approvers, responsibleUsers) {
  if (orderDetails?.multi_principal_approval_tokens) {
    let approversName = [];

    if (orderDetails?.approvers) {
      approversName = approvers.map(([key, _]) => {
        return key.toLowerCase();
      });
    }
    return (
      <div class="d-flex gap-1 approval-progress-bar">
        {approvers
          .sort((A, B) => {
            return A[1].approval > B[1].approval ? 1 : -1;
          })
          .map(([key, val]) => {
            return (
              <div class={`flex-grow-1 approval-progress-bar-item ${val.approval}-item`}>
                <span>{key}</span>
              </div>
            );
          })}
        {responsibleUsers.map(item => {
          if (item && approversName.indexOf(item) == -1) {
            return (
              <div class={`flex-grow-1 approval-progress-bar-item`}>
                <span>{item}</span>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

function renderApprovalComment(data, canApprove) {
  if (canApprove && [0, '0', 'NEW', 'WAITING_FOR_APPROVAL'].indexOf(data.approved) > -1) {
    return (
      <div class="col-lg-12 col-xl-7">
        <h4>Approval Comment</h4>
        <textarea
          class="form-control"
          id={'approval-comment-' + data.id}
          name="approval-comment"
          rows={3}
          maxlength={500}></textarea>
      </div>
    );
  }
}

function renderApprovalButtons(data, canApprove, alreadyApproved, canRetry) {
  return (
    <div class="btn-group detail-actions-btn-group">
      <>
        {[0, '0', 'NEW', 'WAITING_FOR_APPROVAL'].indexOf(data.approved) > -1 && canApprove && (
          <>
            <button
              type="button"
              class="btn btn-success"
              id={`${data.id}-approve-button`}
              onclick={() => orderUpdateHandler(data.id, 'approve')}>
              <span class="fas fa-check" /> Approve
            </button>
            <button
              type="button"
              class="btn btn-danger"
              id={`${data.id}-reject-button`}
              onclick={() => orderUpdateHandler(data.id, 'reject')}>
              <span class="fas fa-times" /> Decline
            </button>
          </>
        )}
        {[-1, '-1', 'FAILED', 'NOT_COMPLETED'].indexOf(data.executed) > -1 && (canRetry || alreadyApproved) && (
          <button
            type="button"
            class="btn btn-warning"
            id={`${data.id}-retry-button`}
            onclick={() => orderUpdateHandler(data.id, 'retry')}>
            <span class="fas fa-redo-alt" /> Retry
          </button>
        )}
      </>
    </div>
  );
}

export default function OrderTabs(props) {
  const { data } = props;

  let permissions = localStorage.permissions ? localStorage.permissions : false;

  const orderDetails = Object.assign(data, data.payload?.reason ? { reason: data.payload.reason } : {});
  let orderDetailsKeys = [
    { Name: 'Order Id', Value: 'id' },
    { Name: 'Action', Value: 'action' },
    { Name: 'Proposer', Value: 'proposer' },
    { Name: 'Creation Date', Value: 'creation_date' },
    { Name: 'Comment', Value: 'approval_comment' },
    { Name: 'Approved', Value: 'approved' },
    { Name: 'Approver', Value: 'approver' },
    { Name: 'Approval Date', Value: 'approval_date' },
    { Name: 'Executed', Value: 'executed' },
    { Name: 'Execution Date', Value: 'execution_date' },
    { Name: 'Execution Response', Value: 'execution_response' },
    { Name: 'Approvers', Value: 'approvers_display', HTML: true },
  ];

  if (data?.account_id) {
    baseApiAxios.getAccount(data.account_id).then(account => {
      const isProposerResponsibleForAccount = checkResponsibility(account, data.proposer);
      if (isProposerResponsibleForAccount) {
        $('#proposerPermissions > ul').append(<li>Account Responsible</li>);
      }
    });
  }
  if (data.payload?.reason) orderDetailsKeys.splice(4, 0, { Name: 'Reason', Value: 'reason', HTML: true });

  // filter approvers/approver depending on approvers col
  // one for four-eyes principle
  // the other for the rest
  let newList;
  let approvers = [];

  // init flag for display approval section
  let canApprove = false;
  let alreadyApproved = false;
  let canRetry = false;

  if (data?.multi_principal_approval_tokens) {
    newList = orderDetailsKeys.filter(
      item => ['approval_comment', 'approver', 'approval_date'].indexOf(item.Value) === -1,
    );

    // give default approvers display to empty string
    orderDetails['approvers_display'] = '';

    if (data?.approvers) {
      approvers = Object.entries(orderDetails['approvers']);

      orderDetails['approvers_display'] = approvers
        .map(([key, val]) => {
          return `Approver: ${key}\nApproval: ${val.approval}\nDate: ${val.approval_date}\ncomment: ${val.approval_comment}\n`;
        })
        .join('\n');
    }

    if (data?.account_id) {
      baseApiAxios.getAccount(data.account_id).then(response => {
        if (response) {
          const ops_responsibles = response.cost_data?.ops_responsibles
            ? response.cost_data.ops_responsibles.map(responsible => responsible.user_id)
            : [];
          const responsibleUsers = Array.from(
            new Set([
              response.it_responsible ? response.it_responsible.toLowerCase() : undefined,
              response.primary_responsible ? response.primary_responsible.toLowerCase() : undefined,
              response.sec_responsible ? response.sec_responsible.toLowerCase() : undefined,
              ...ops_responsibles,
            ]),
          );

          // render Progressbar
          $(`#${orderDetails.id}-approval-progress-bar`).html(
            renderApprovalProgressbar(orderDetails, approvers, responsibleUsers),
          );

          // check if user has already approved
          alreadyApproved = checkAlreadyApproved(orderDetails.approvers, COGNITO.user.email);
          // enable approval section if user can approve
          canApprove = !alreadyApproved && checkResponsibility(response, COGNITO.user.email);
          // enable retry button if user can retry
          canRetry = checkValueAgainstList('manage_orders', permissions);
          // render Approval comment and buttons
          $(`#${orderDetails.id}-approval-comment-block`).html(renderApprovalComment(data, canApprove));
          $(`#${orderDetails.id}-approval-buttons-block`).html(
            renderApprovalButtons(data, canApprove, alreadyApproved, canRetry),
          );
        }
      });
    } else {
      const orderType = orderDetails.action;
      switch (orderType) {
        case 'temporary-privilege-escalation':
          const requiredPermission = 'manage_temporary_privilege_escalation_orders';
          alreadyApproved = checkAlreadyApproved(orderDetails.approvers, COGNITO.user.email);
          canApprove =
            !alreadyApproved &&
            checkValueAgainstList(requiredPermission, permissions) &&
            orderDetails.proposer.toLowerCase() != COGNITO.user.email.toLowerCase();

          canRetry = checkValueAgainstList(requiredPermission, permissions);
          $(`#${orderDetails.id}-approval-comment-block`).html(renderApprovalComment(data, canApprove));
          $(`#${orderDetails.id}-approval-buttons-block`).html(
            renderApprovalButtons(data, canApprove, alreadyApproved, canRetry),
          );
          break;
        default:
          break;
      }
    }
  } else {
    newList = orderDetailsKeys.filter(item => ['approvers_display'].indexOf(item.Value) === -1);

    // enable approval section if user can approve
    // Special edge case for temp-privilege order type: it can be submitted from the list order page because it's a modal.
    // And if the reload-orders button is pressed before the multi_principal_approval_token is available , we don't want the approve buttons to be displayed.
    canApprove =
      data?.action === 'temporary-privilege-escalation' ? false : checkValueAgainstList('manage_orders', permissions);
    // enable retry button if user can retry
    canRetry = checkValueAgainstList('manage_orders', permissions);
  }

  const rawResponseEnabled = !!data.lambda_response;

  // Order
  const proposerPermissions = data.proposer_permissions
    ? [
        ...(data.proposer_permissions.federated_user_roles || []),
        ...(data.proposer_permissions.cdh_user_roles || []),
        ...(data.proposer_permissions.idc_user_roles || []),
      ]
    : [];

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-bs-target={'#order_details_' + data.id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="true"
            aria-expanded="true">
            Order
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link'}
            data-bs-target={'#raw_event_' + data.id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Raw Event
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (rawResponseEnabled ? '' : ' disabled')}
            data-bs-target={'#raw_response_' + data.id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Raw Response
          </a>
        </li>
      </ul>
      <div class="tab-content detailsTab">
        <div class="tab-pane fade active show" id={'order_details_' + data.id} role="tabpanel">
          <div class="row" name="order-details-container">
            <div class="col-lg-12 col-xl-7" name="order-details-left-side">
              <div class="detailsContent">
                <h4>Order Details</h4>
                <div>
                  <InfoTable data={orderDetails} rowInfos={newList} />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xl-5" name="order-details-right-side">
              {data.proposer_permissions && (
                <div class="detailsContent" id="proposerPermissions">
                  <h4>Proposer Permissions</h4>
                  <ul>
                    {proposerPermissions.map(permission => {
                      return <li>{permission}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id={'raw_event_' + data.id} role="tabpanel">
          <div class="row" name="raw-event-container">
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <h4>Raw Event Data</h4>
                {data.payload && createJsonCodeBlock(data.payload)}
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id={'raw_response_' + data.id} role="tabpanel">
          <div class="row" name="raw-response-container">
            <div class="col-lg-12 col-xl-12">
              {rawResponseEnabled && (
                <div class="detailsContent">
                  <h4>Raw Event Response</h4>
                  {createJsonCodeBlock(data.lambda_response)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div class="detailsContent" id={`${orderDetails.id}-approval-progress-bar`}>
          {renderApprovalProgressbar(orderDetails, approvers, [])}
        </div>
        <div class="detailsContent" id={`${orderDetails.id}-approval-comment-block`}>
          {renderApprovalComment(data, canApprove)}
        </div>
        <div class="detailsContent" id={`${orderDetails.id}-approval-buttons-block`}>
          {renderApprovalButtons(data, canApprove, alreadyApproved, canRetry)}
        </div>
      </div>
    </div>
  );
}
