import {
  enableRemoveFromAllAccounts,
  renderRemoveADUserForm,
  submitRemoveADOrder,
  updateListRolesButton,
  defaultIamRoles,
} from './js/portal/user-management';
import UserDropdown from './jsx/components/forms/UserDropdown';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import { checkUserAccess } from './js/portal/main';
import { disableFeatureFlagControlledElements, enableFeatureFlagControlledElements } from './js/portal/feature_flags';
import ClassicADUserMgmtMessage from './jsx/components/tiles/ClassicADUserMgmtMessage';

export default function UsersADRemovePage() {
  const page = (
    <div>
      <ClassicADUserMgmtMessage />
      <div class="row classic-ad-user-mgmt-form d-none">
        <div class="col-lg-12">
          <form name="user-remove-form" id="user-remove-form" method="POST">
            <div class="card form-card">
              <div class="card-header">Remove AD User from IAM Role</div>
              <div class="card-body">
                <div id="form-error" class="alert alert-danger" style="display: none">
                  <ul id="form-error-list" />
                </div>
                <div id="form-success" class="alert alert-success" style="display: none">
                  <ul id="form-success-list" />
                </div>

                <div id="infobox" class="card infobox">
                  <div class="card-body">
                    With this form you can remove Active Directory users from existing AWS accounts. A validation of the
                    account email you typed in or already existing assignments can not be validated in this form. For
                    more information please have a look in our{' '}
                    <a
                      href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/"
                      target="_blank"
                      rel="noopener noreferrer">
                      Developer Portal
                      <i class="px-1 fas fa-external-link-alt" />
                    </a>
                    .
                  </div>
                </div>

                <UserDropdown id="user-email" multiple />

                <AccountDropdown
                  id="aws-account-id"
                  onchange={(_eventTypeId, _eventCategory, _selectedOption, accountIds) => {
                    document.getElementById('submitButton').disabled = !checkUserAccess(['manage_users'], accountIds);
                    updateListRolesButton(defaultIamRoles.concat(['all']));
                  }}
                  multiple
                  short
                />

                <div id="allAccountsRow" class="form-group row">
                  <label class="col-form-label" />
                  <div class="col">
                    <label class="checkbox-inline">
                      <input
                        type="checkbox"
                        id="allAccounts"
                        name="allAccounts"
                        value="over"
                        onclick={() => enableRemoveFromAllAccounts()}
                      />
                      <strong>Remove User from all accounts</strong>
                    </label>
                  </div>
                </div>

                {/* selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/ */}
                <div class="form-group row">
                  <label for="target-roles" class="col-form-label">
                    Target Roles
                  </label>
                  <div class="col">
                    <div class="input-group">
                      <select
                        required
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        id="target-roles"
                        name="target-roles"
                        data-size="10"
                        data-actions-box="false"
                        data-none-selected-text="- Select target IAM Roles -"
                        data-selected-text-format="count > 5"
                        data-max-options="5"
                        multiple
                      />
                      <button type="button" class="btn btn-light-grey input-group-btn" id="readRolesButton">
                        List Roles from Account(s)
                      </button>
                    </div>

                    <div id="infobox-target-roles" class="card infobox mt-3">
                      <div class="card-body">
                        <code>All:</code> This option will remove the given user from all available IAM Roles in an AWS
                        Account, beside of administrative IAM Roles for the BMW Framework for Public Cloud.
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div id="exceptRolesRow" class="form-group row" style="display: none">
                  <label for="except-roles" class="col-form-label">
                    Except Roles
                  </label>
                  <div class="col">
                    <div class="input-group">
                      <select
                        class="form-control form-select selectpicker"
                        data-live-search="true"
                        data-show-subtext="true"
                        id="except-roles"
                        name="except-roles"
                        data-size="10"
                        data-actions-box="false"
                        data-none-selected-text="- Select IAM Roles to exclude -"
                        data-selected-text-format="count > 5"
                        data-max-options="5"
                        multiple
                      />
                    </div>

                    <div class="card infobox mt-3">
                      <div class="card-body">
                        This option will exclude the selected roles from the removal process. Leave empty if you do not
                        want to exclude any role.
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer" align="right">
                <button type="submit" class="btn btn-success" id="submitButton" value="Remove">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  Remove
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  async function onPageReady() {
    enableFeatureFlagControlledElements('classic-ad-user-mgmt.remove_ad_user_classic', 'classic-ad-user-mgmt-form');
    disableFeatureFlagControlledElements('classic-ad-user-mgmt.remove_ad_user_classic', 'classic-ad-user-mgmt-message');

    renderRemoveADUserForm();
    $('#user-remove-form')
      .on('submit', function (e) {
        e.preventDefault();
      })
      .validate({
        submitHandler: submitRemoveADOrder,
      });

    updateListRolesButton();
  }

  return [page, onPageReady];
}
