import { addApiKeyPermissionsMapping, initApiKeysTable } from './js/portal/apikeys';
import { baseApiAxios } from './js/portal/api';
import { CONF } from './js/portal/environment';
import { Datatable } from './Datatable';
import * as SwaggerUI from 'swagger-ui-dist/swagger-ui-bundle';

// This prevents the Zenscroll library (which is transitively imported by Swagger UI) to install a
// global click event handler which would mess up some Bootstrap components like nav tabs.
// It needs to be set before the Swagger-UI library and its dependencies are loaded!
window.noZensmooth = true;

const tableId = 'apiKeysTable';

export default function LoginManageApiKeysPage() {
  const page = (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div id="form-error" class="alert alert-danger" style="display: none">
            <ul />
          </div>
          <div id="form-success" class="alert alert-success" style="display: none">
            <ul />
          </div>
        </div>
      </div>

      {/* WELL AND AWS ACCOUNT COUNTER */}
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card infobox">
            <div class="card-body">
              <ul class="overview">
                <li class="overview">
                  <i class="fas fa-sign-in-alt" /> (Public) API Endpoint:{' '}
                  <a id="user-api-endpoint" target="_blank" rel="noopener noreferrer" />
                </li>
                <li class="overview">
                  <a class="nobr" href={CONF.swaggerUrl} target="_blank" rel="noopener noreferrer">
                    <i class="fas fa-book" /> Swagger Documentation
                  </a>{' '}
                  <small>(see bottom of the page)</small>
                </li>
                <li class="overview">
                  <a
                    class="nobr"
                    href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/3_runyourapplication/selfservices/aws_portal_user_api/portal_user_api/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i class="fas fa-book" /> Developer Portal
                  </a>{' '}
                  <small>(internal Documentation)</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="edit-api-key-form" style="display: none" />
      <div class="row" id="edit-api-key-permissions-form" style="display: none" />

      <Datatable tableId={tableId} />

      <br />
      <hr class="portal" />

      <div id="show-swaggerui-div" class="d-flex justify-content-center">
        <div class="form-check form-switch">
          <input
            type="checkbox"
            class="form-check-input portal-check-input"
            id="show-swaggerui-switch"
            data-bs-toggle="collapse"
            data-bs-target="#swaggerui-collapse"
          />
          <label class="form-check-label" for="show-swaggerui-switch">
            Show SwaggerUI
          </label>
        </div>
      </div>
      <div id="swaggerui-collapse" class="collapse">
        <div id="swaggerui"></div>
      </div>
    </>
  );

  function onPageReady() {
    /**
     * Load data as soon as the whole page is loaded
     */
    $('#user-api-endpoint').text(CONF.apiUserAPIEndpoint).attr('href', CONF.apiUserAPIEndpoint);

    initApiKeysTable(tableId, 'portal');
    baseApiAxios
      .postApiKeyData({ action: 'list-api-resources-methods', target: 'portal' })
      .then(addApiKeyPermissionsMapping);

    fetch(CONF.swaggerUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch Swagger JSON from S3: ${response.statusText}`);
        }
        return response.json();
      })
      .then(apiSpec => {
        SwaggerUI({
          spec: apiSpec,
          domNode: document.getElementById('swaggerui'),
        });
      })
      .catch(error => {
        console.error('Error loading Swagger API specification:', error);
      });
  }

  return [page, onPageReady];
}
